
import './App.css';
import {Route, Routes, HashRouter} from "react-router-dom"
import Login from "./page/login/index.jsx"
import Forgetpwd from "./page/login/forgetpwd.jsx"
import Register from "./page/login/register.jsx"
import RegisterInfo from "./page/login/registerInfo.jsx"
// import RegisterCorp from "./page/login/registerCorp.jsx"
// import RegisterPerson from "./page/login/registerPerson.jsx"
import RegisterOk from "./page/login/registerOk.jsx"
// import Admin from "./page/admin/index.jsx"
import Enter from "./page/admin/enter.jsx"
import EnterDetail from "./page/enter/detail/index.jsx"
// import Timebutton from "./page/login/timebutton.jsx"
// import Testupload from "./page/login/testupload.jsx"
// import Timetest from "./page/testTable/timertest.jsx"
// import AdminLogin from "./page/admin/login.jsx"
// import AdminDetail from "./page/case/detail/index.jsx"
// import AdminTest from "./page/admintest/index.jsx"

// import JudgeLogin from "./page/judge/login.jsx"
// import JudgeList from "./page/judge/list.jsx"
// import JudgeDetail from "./page/judge/detail.jsx"


function App() {
  return (
    <div className="App">
      
      <HashRouter>
        <Routes >
          {/* <Route path="/testupload" element={<Testupload/>}></Route>
          <Route path="/timebutton" element={<Timebutton/>}></Route>
          <Route path="/timetest" element={<Timetest/>}></Route> */}
          
          {/* <Route path="/judge/login" element={<JudgeLogin/>}></Route>
          <Route path="/judge/list" element={<JudgeList/>}></Route>
          <Route path="/judge/detail" element={<JudgeDetail/>}></Route> */}

          <Route path="/login" element={<Login/>}></Route>
          <Route path="/login/forgetpwd" element={<Forgetpwd/>}></Route>
          <Route path="/register" element={<Register/>}></Route>
          {/* <Route path="/register/corp" element={<RegisterCorp/>}></Route> */}
          {/* <Route path="/register/person" element={<RegisterPerson/>}></Route> */}
          <Route path="/register/info" element={<RegisterInfo/>}></Route>
          <Route path="/register/ok" element={<RegisterOk/>}></Route>
          <Route path="/enter/detail" element={<EnterDetail/>}></Route>
          <Route path="/enter/*" element={<Enter/>}></Route>
          {/* <Route path="/admin/login" element={<AdminLogin/>}></Route>
          <Route path="/admin/detail" element={<AdminDetail/>}></Route>
          <Route path="/admintest" element={<AdminTest/>}></Route> */}
          <Route path="*" element={<Enter/>}></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
