/*
 * @Author: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @Date: 2024-08-05 19:05:23
 * @LastEditors: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @LastEditTime: 2024-08-27 11:33:31
 * @FilePath: \kpyunfsservicee:\git\topdigital\topdweb\user\src\page\components\CustomProList\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Badge,Select,Modal,Popconfirm } from 'antd';
import React, { Component,useState,useEffect }  from 'react';
import Icon,{ ExclamationCircleOutlined } from '@ant-design/icons';

import './index.css';
const { confirm } = Modal;

export default  function Index(props)  {
  const {columsName, text, onChangFun} = props;

  return (
    <div key="id" style={{ display: 'flex', justifyContent: 'space-around' }}>
    {
      Object.keys(columsName).map((field, index) => (
        // console.log('CustomProList - ', field, text[field], columsName[field]),
        // console.log(columsName[field]['valueEnum'][text[field]]),
        // console.log(typeof(text[field])),
          <div key={index} title={
              columsName[field]['valueEnum']||columsName[field]['title']=='logo' ? "": 
              (
                columsName[field]['render'] ? columsName[field]['render'](text[field]) : text[field]
              )
          } style={{ color:'#000000D9',wordWrap:"break-word",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden", width:columsName[field]['width']}}>
            {
              columsName[field]['valueEnum'] ? 
              (
                columsName[field]['valueEnum'][text[field]] ?
                (
                  columsName[field]['valueEnum'][text[field]]['text']
                  // columsName[field]['valueEnum'][text[field]]['status'] ?
                  // <Badge status={columsName[field]['valueEnum'][text[field]]['status']} text={columsName[field]['valueEnum'][text[field]]['text']} /> :
                  // (
                  //   columsName[field]['edit'] ? 
                  //   listCol(field) :
                  //   columsName[field]['valueEnum'][text[field]]['text']
                  // )
                ) :
                text[field]
              ) : 
              (
                columsName[field]['render'] ? columsName[field]['render'](text[field]) : text[field]
              )
            }
          </div>
      ))
    }
  </div>
);
}
