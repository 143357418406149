import React, {useEffect,useImperativeHandle,forwardRef} from 'react'
 
const UEditor = (props,ref) => {
    let editor = null

    const onChange = typeof props["onChange"] !== "undefined"?props.onChange:()=>{}

    useEffect(() => {
        if(props.show)
            setConfig(props.initData,props.config,props.setContent)
    },[props.show])
    // 初始化编辑器
    const setConfig = (initData,config,setContent) => {
        try{
            window.UE.delEditor(props.id);
            editor = window.UE && window.UE.getEditor(props.id, {
                autoFloatEnabled: false,
                initialFrameHeight: (config&&config.initialFrameHeight) || 450,
                initialFrameWidth: (config&&config.initialFrameWidth) || '100%',
                zIndex: 1000,
            })

            editor.addListener("ready",function(){
                editor.setContent(initData)
            });
            editor.addListener("blur",function(){
                setContent(editor.getContent())
            });
            //add by jerry 提供上层空间接受 onChange 事件
            editor.addListener("contentChange", (data)=>{

                onChange(editor.getContent());
            })

        } catch(e) {
            console.log('UEdit error !!!!!!!!!!!!!!!!!!', e)
        }
    }

    return (
      <script id={props.id} type="text/plain" />
    )
}
 
export default forwardRef(UEditor) 