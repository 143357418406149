import Icon,{EditOutlined,FolderViewOutlined,CopyOutlined,DeleteOutlined  } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button ,Table, Modal,List, Typography } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import { ProList } from '@ant-design/pro-components';
import './index.css';
// import {operateLog} from './service.js';
// import {customerOptions} from '../bcroom/service.js';
// import checkLogin from "../../request/checkLogin.js"
import CustomProList from '../../components/CustomProList';
import { waitTime } from '../../../utils/global.js';
// import { EditIcon } from '../components/icons';
import Addtc from './addprint.jsx';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

const { Header, Content, Sider } = Layout;

const App = props => {
  // const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  const actionRef = useRef();

  const [loading_checklist,setChecklistloading] = useState(false);
  const [showEnlist,setShowEnlist] = useState(true);
  const [showAddtc,setShowAddtc] = useState(false);
  const [showModalType,setShowModalType] = useState(false);
  const [curModalType,setCurModalType] = useState(0);
  const [bEdit,setBEdit] = useState(false);
  const [rowInfo,setRowInfo] = useState({});

  const columsName = {
    'showcomp' : {
      title:'参赛单位', 
      width:'20%'
    },
    'casename' : {
      title:'作品名称', 
      width:'20%'
    },
    'categoryname' : {
      title:'组别', 
      width:'20%'
    },
    'result' : {
      title:'获奖结果', 
      width:'10%'
    },
    'ptype' : {
      title:'加印内容', 
      width:'10%'
    },
    'logo' : {
      title:'logo', 
      width:'10%',
      render: (text) => {
        if (text == 'logo') {
          return <span style={{position:"absolute"}}>logo</span>;
        } else {
          return <img src={text} width={30} height={30} style={{position:"absolute",top:-6,}} />;
        }
      }, //这里放后台返回的图片的路径或者整个<img/>

    },
    'status' : {
      title:'审核状态', 
      width:'7%',
      valueEnum: {
        0: {
          text: '审核中',
          status: 'processing',
        },
        1: {
          text: '审核通过',
          status: 'success',
        },
      },
    },
    // 'op' : '操作',
  };
  var columsRow = {};
  Object.keys(columsName).map((field, index) => (
    columsRow[field] = columsName[field]['title']
  ))
  columsRow['id'] = "0";
  columsRow['content'] = columsRow;

  // 页面初始化
  useEffect(() => {
    // if(currentUserinfo.userType == 99)
      // setShowSelectClient("block");
    // else
    //   setCanAddmember(true);

    // 获取客户列表
    // try {
    //   customerOptions({}).then((res) => {
    //   // console.log('customerOptions-res-----------------', res);
    //   var flag = checkLogin(res.data)
    //   // console.log('flag: ', flag);
    //   if (flag) {
    //     setClientList(res.data.data)
    //   }
    //   // else
    //   //   message.error(res.data.msg);
    // });
    // } catch (error) {
    //   // message.error(error);
    // }

  }, []);
  
  const getOpList = async (params) =>{
    var list = [];
    try {
      await getApiData('U', 'U_printList', {}).then((res) => {
        console.log('U_printList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          list = res.data.d.list;
        }
      });
      } catch (error) {
        
      }
    return list;

  }

  const closeAddtc = () => {
    setShowEnlist(true);
    setShowAddtc(false);
  };


  return (
    // <Content
    //   className="site-layout-background"
    //   style={{
    //     padding: 10,
    //     margin: 0,
    //     minHeight: "100%",
    //   }}
    // >

    <div>
          
    <ProList rowKey="id" 
      style={{display:showEnlist?"block":"none"}}
      headerTitle={"加印列表"}
        search={{
          filterType: 'light',
        }}
        // pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        toolBarRender={() => {
          return [
              <Button key="btn_add"  shape="round" style={{backgroundColor:"rgb(152,100,201)", color:'white', height:40}}
              onClick={() => {
                setBEdit(true)
                setShowEnlist(false);
                setShowAddtc(true);
              }}>+ 新增加印</Button>,
          ];
        }} 
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setChecklistloading(true);
          var tableData = [columsRow];
          // console.log('params----------------', params);
          const res = await getOpList(params);
          // console.log('res--------------', res);
          if(res){
            res.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k+1] = v;
              tableData[k+1]['content'] = {...v};
            })
          }
          // console.log('tableData--------------', tableData);
          setChecklistloading(false);
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{
          // operName: {
          //     // dataIndex: 'name',
          //     title: '操作人员',
          // },
          // title: {
          //   // 自己扩展的字段，主要用于筛选，不在列表中显示
          //   title: '系统模块',
          //   // dataIndex: 'scene',
          //   valueType: 'select',
          //   valueEnum: {
          //     "直播舱": {
          //       text: '直播舱',
          //       status: 'success',
          //     },
          //     "养号舱": {
          //       text: '养号舱',
          //       status: 'error',
          //     },
          //     "无人直播舱": {
          //       text: '无人直播舱',
          //       status: 'success',
          //     },
          //     // "素材管理": {
          //     //   text: '素材管理',
          //     //   status: 'error',
          //     // },
          //     "APP管理": {
          //       text: 'APP管理',
          //       status: 'error',
          //     },
          //     "成员管理": {
          //       text: '成员管理',
          //       status: 'error',
          //     },
          //     "客户管理": {
          //       text: '客户管理',
          //       status: 'error',
          //     },
          //     "审核管理": {
          //       text: '审核管理',
          //       status: 'error',
          //     },
          //   },
          // },
          // status: {
          //   // 自己扩展的字段，主要用于筛选，不在列表中显示
          //   title: '操作状态',
          //   // dataIndex: 'status',
          //   valueType: 'select',
          //   valueEnum: {
          //     0: {
          //       text: '正常',
          //       status: 'success',
          //     },
          //     1: {
          //       text: '异常',
          //       status: 'error',
          //     },
          //   },
          // },

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },

        //   actions: {
        //     render: (text, data) => [
        //         data.id == '0' ? <div style={{ textAlign:"left",color:'black',width:45 }}>操作</div> : 
        //       <Space>
        //       <FolderViewOutlined 
        //         onClick={async () => {
        //           // console.log(data, text)
        //           setRowInfo(data);
        //           await waitTime(100);
        //           setBEdit(false)
        //           setShowEnlist(false)
        //           setShowAddtc(true)
        //           // setCurClientid(data.id);
        //           // setShowEditPassword(true);
        //         }}
        //       />
        //       <EditOutlined 
        //         onClick={() => {
        //           // console.log(data, text)
        //           setBEdit(true)
        //           setShowEnlist(false)
        //           setShowAddtc(true)
        //           // setCurClientid(data.id);
        //           // setShowEditPassword(true);
        //         }}
        //       />
        //       <DeleteOutlined />

        //       </Space>
        //     ],
        // },

        }} 
        
        />

      <Addtc 
        showAddtc = {showAddtc}
        bEdit = {bEdit}
        rowInfo = {rowInfo}
        closeAddtc = {closeAddtc}
        refreshList={()=>{actionRef.current?.reload();}}
      />
      </div>

    // </Content>

);
}
export default App;