import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,InputNumber , Space,Divider,Image,Select} from 'antd';
import Icon,{EditOutlined ,LoadingOutlined, PlusOutlined,DeleteOutlined } from '@ant-design/icons';
import './index.css';
import UploadFile from '../../components/UploadFile';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const App =(props)=>{
    const {showAddtc, bEdit,rowInfo,closeAddtc,refreshList} = props;
    console.log("addprint----------- ", props)
    const [form] = Form.useForm();
    // const [bEdit,setBEdit] = useState(true);
    const [enterprise,setEnterprise] = useState('');
    const [casename,setCasename] = useState('');
    const [group,setGroup] = useState('');
    const [awardResult,setAwardResult] = useState('');
    const [printtypeList,setPrinttypeList] = useState([]);
    const [productList,setProductList] = useState([]);
    const [productInfo,setProductInfo] = useState({});

    const [percent, setpercent] = useState(50);
    const [percentShow,setPercentShow] = useState(true);
    const [imageUrl, setImageUrl] = useState("");
    const [money,setMoney] = useState(0);
    const [unitprice,setUnitprice] = useState(0);
    const [printcnt,setPrintcnt] = useState(1);
    // const [imageUrl, setImageUrl] = useState("https://kp-cloud-store.tos-cn-shanghai.volces.com/%E6%B8%B8%E6%88%8F%E5%BA%93%E4%B8%9A%E5%8A%A1/public/apks/62fb66acc287533b6d59284e_d63d19c9b3d9220b85dc23a5caebc9ab.png");
    const [loading, setLoading] = useState(false);

    // const prodInfo = {
    //     '1' : {
    //       name: '作品1',
    //       enterprise: '企业1',
    //       group: '分组1',
    //       awardResult: '一等奖',
    //     },
    //     '2' : {
    //       name: '作品2',
    //       enterprise: '企业2',
    //       group: '分组2',
    //       awardResult: '二等奖',
    //     },
    //   };
    
    useEffect(() => {
      if(showAddtc){
        getOpList();
        getPrinttype();
      }
      else{
        setEnterprise('');
        setCasename('');
        setGroup('');
        setAwardResult('');
        setPrinttypeList([]);
        setProductList([]);
        setProductInfo({});
    
        setpercent(0);
        setPercentShow(false);
        setImageUrl("");
        setMoney(0);
        setUnitprice(0);
        setPrintcnt(1);
        setLoading(false);
    
        form.resetFields();
    
      }
    }, [showAddtc]);

    useEffect(() => {
      setMoney(parseFloat(unitprice)*printcnt);
    }, [printcnt, unitprice]);

    

  // 获取我的案例列表
  const getOpList = async () =>{
    try {
      await getApiData('U', 'U_mycaseList', {win:1}).then((res) => {
        console.log('U_mycaseList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          var list = [];
          var info = {};
          for(let item of res.data.d.list){
            list.push({value:item.symbol, label:item.symbol});
            info[item.symbol] = item;
          }
          setProductList(list);
          setProductInfo(info);
        }
      });
      } catch (error) {
        
      }
  }

  // 获取加印类别
  const getPrinttype = async () =>{
    try {
      await getApiData('U', 'U_getSelectList', {ltype: ["printtype"]}).then((res) => {
        console.log('U_getSelectList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          var list = [];
          for(let item of res.data.d.list.printtype){
            list.push({value:item.name, label:item.name, fee:item.fee});
          }
          setPrinttypeList(list);
        }
      });
      } catch (error) {
        
      }
  }

    // 选择案例
    const onProdChange = async(value)=>{
        console.log(value)
        form.setFieldValue("showcomp", productInfo[value].showcomp);
        form.setFieldValue("casename", productInfo[value].name);
        // setEnterprise(productInfo[value].companyname);
        // setCasename(productInfo[value].name);
        setGroup(productInfo[value].categoryname);
        setAwardResult(productInfo[value].result);

    }

    // 选择加印类别
    const onPtypeChange = async(value, option)=>{
        console.log(value)
        console.log(option)
        setUnitprice(option.fee);
    }

    // 输入加印份额
    const onPrintcntChange = async(value)=>{
        console.log(value)
        setPrintcnt(value);
    }

    // 提交
    const onFinish = async(value)=>{
        console.log(value);
        if(!value.symbol){
          message.error("请先选择作品编号");
          return;
        }
        if(!imageUrl){
          message.error("请提交logo");
          return;
        }
        value.caseid = productInfo[value.symbol].id;
        delete value.symbol;
        value.logo = imageUrl;
        try {
          setLoading(true);
          await getApiData('U', 'U_createPrint', value).then((res) => {
            setLoading(false);
            console.log('U_createPrint-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              message.success("新增成功");
              closeAddtc();
              refreshList();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    
    }

  return (
    <div  id="box" className='container' style={{width:"70%",display:showAddtc?"block":"none"}} >
        <div style={{background:"white", borderRadius:18, overflow:'hidden'}}>
            {/* <Button type="text" style={{display:bEdit?"none":"block",float:"right",color:"#AAA"}} icon={<EditOutlined />} onClick={()=>{setBEdit(true)}}>编辑</Button> */}
            <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:5,offset:2}}
            style={{width:"100%"}}
            initialValues={{printcnt:1}}
        >
          <h4 style={{ textAlign:"left", fontSize:15}}>加印信息</h4>
          <Divider className='add_divide' />
            {/* <div style={{height:100, marginBottom:20}}>
              {imageUrl ? (
                <Image
                  height={100}
                  src={imageUrl}
                />
              ) : "" }
            </div> */}
            <Form.Item name="symbol"  
                label="作品编号：" >
                <Select
                    placeholder="请选择"
                    onChange={onProdChange}
                    // defaultValue={"1"}
                    // allowClear
                    // style={{width:"50%"}}
                    disabled={bEdit?false:true}
                    options={productList}
                />
            </Form.Item>
            <Form.Item
                label="参赛单位"
                name="showcomp"
                extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"100%",textAlign:"left"}}>*若需对参赛单位进行修改，仅支持获奖作品中的关联企业/品牌</div>}
            >
            <Input placeholder="" value={enterprise} />
            </Form.Item>
            <Form.Item
                label="作品名称"
                name="casename"
                extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"100%",textAlign:"left"}}>*若需对作品名称进行修改，仅支持部分语序微调</div>}
            >
            <Input placeholder="" value={casename} />
            </Form.Item>
            <Form.Item
                label="组别"
                // name="userName"
            >
            <Input placeholder="" value={group} bordered={false} disabled={true} />
            </Form.Item>
            <Form.Item
                label="获奖结果"
                // name="userName"
            >
            <Input placeholder="" value={awardResult} bordered={false} disabled={true} />
            </Form.Item>
            <Form.Item label="加印类别" name="ptype">
              <Select
                    placeholder="请选择"
                    onChange={onPtypeChange}
                    // defaultValue={"1"}
                    // allowClear
                    // style={{width:"50%"}}
                    disabled={bEdit?false:true}
                    options={printtypeList}
                />
            </Form.Item>
            <Form.Item
                label="logo："
                name="logo"
                style={{textAlign:"left"}}
                extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:20,width:"100%",textAlign:"left"}}>*如需更换logo或展示多个logo，仅支持获奖作品中的关联企业/品牌；多个logo需自行编辑后上传。logo仅用于证书制作</div>}
            >
              <Space direction="horizontal" size={"large"}>
                {bEdit ? (
                  <UploadFile 
                    from = {"U"}
                    upButton = {<img src={[require("../../../img/uplogo.png")]} width={80} />}
                    uploadFileType = {".png, .jpg, .jpeg, .gif"}
                    uploadFileSize = {50*1024*1024}
                    setpercent = {setpercent}
                    setPercentShow = {setPercentShow}
                    setFileUrl = {setImageUrl}
                  />
                ) : "" }
                <div style={{height:90}}>
                  {imageUrl ? (
                    <Image
                      height={80}
                      src={imageUrl}
                    />
                  ) : "" }
                  <Button danger type='primary' style={{width:"100%", height:20, borderRadius:3, margin:"8px 0",display:bEdit && imageUrl?"block":"none"}} icon={<DeleteOutlined style={{fontSize:10}} />} onClick={()=>{setImageUrl("")}} />
                </div>
              </Space>
            </Form.Item>
            <Form.Item label="加印份额"  style={{textAlign:"left", marginBottom:0}}>
              <Space direction="horizontal">
                <Form.Item name="printcnt" style={{width:"80%"}}
                >
                    <InputNumber min={1} style={{width:"100%"}} onChange={onPrintcntChange} disabled={bEdit?false:true}/>
                </Form.Item>
                <Form.Item style={{display:bEdit?"block":"none"}}>
                <span style={{color:"red"}}>金额：{money}</span>
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item
                label="其他要求备注"
                name="other"
                extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:10,width:"100%",textAlign:"left"}}>*如有其他备注可填写，以上所有信息经由组委会审核后方可安排加印，如有疑问可联系赛事顾问</div>}
            >
            <Input placeholder="" />
            </Form.Item>
            <Form.Item style={{display:bEdit?"block":"none"}}>
            <Space size={50} style={{paddingBottom:20, paddingTop:20}}>
            <Button type="primary" htmlType="submit" shape='round' loading={loading} style={{backgroundColor: "rgb(152,100,201)",borderColor: "#000000",marginTop:20, padding:"0 25px"}}>
                提交
            </Button>
            <Button type="primary"  shape='round' style={{backgroundColor: "#c1c1c1",borderColor: "#000000",marginTop:20, padding:"0 25px"}} onClick={()=>closeAddtc()}>
                取消
            </Button>
            </Space>
            </Form.Item>
        </Form>
        </div>

    </div>
  )
}
export default App
