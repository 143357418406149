import Icon,{EditOutlined,FolderViewOutlined,CopyOutlined,DeleteOutlined  } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu,Input,Select,Card,Space,Col, Row ,Button ,Table, Modal,List, Typography } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import { ProList } from '@ant-design/pro-components';
import './index.css';
// import {operateLog} from './service.js';
// import {customerOptions} from '../bcroom/service.js';
// import checkLogin from "../../request/checkLogin.js"
import CustomProList from '../../components/CustomProList';
// import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../../utils/global.js';
// import { EditIcon } from '../components/icons';
// import Addtc from '../addtc/index.jsx';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

const { Header, Content, Sider } = Layout;
const screenHeight = document.body.clientHeight;

const App = props => {
  // const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  const actionRef = useRef();

  const [loading_checklist,setChecklistloading] = useState(false);
  const [showEnlist,setShowEnlist] = useState(true);
  const [config,setConfig] = useState({footer:{}});
  const [totalFee,setTotalFee] = useState(0);

  const columsName = {
    'symbol' : {
      title:'作品编号', 
      width:'10%'
    },
    'showcomp' : {
      title:'参赛单位', 
      width:'10%'
    },
    'name' : {
      title:'作品名称', 
      width:'10%'
    },
    'categoryname' : {
      title:'组别', 
      width:'10%'
    },
    'result' : {
      title:'获奖结果', 
      width:'10%'
    },
    'signupfee' : {
      title:'报名费', 
      width:'10%'
    },
    'price' : {
      title:'赛事服务费', 
      width:'10%'
    },
    'printfee' : {
      title:'加印费', 
      width:'10%'
    },
    'rewardcode' : {
      title:'领奖编号',
      width:'10%'
    },
    'rewardtime' : {
      title:'领奖时间',
      width:'10%'
    }
    // 'op' : '操作',
  };
  var columsRow = {};
  Object.keys(columsName).map((field, index) => (
    columsRow[field] = columsName[field]['title']
  ))
  columsRow['id'] = "0";
  columsRow['content'] = columsRow;

  // 页面初始化
  useEffect(() => {
    // getConfig();
    getAllFee();
  }, []);
  
  // const getConfig = async()=>{
  //   try {
  //       return await getApiData('U', 'U_getConfig', {}).then((res) => {
  //         console.log('U_getConfig-res-----------------', res);
  //         var flag = checkLogin('U', res.data)
  //         if (flag) {
  //           setConfig(res.data.d.config)
  //         }
  //       });
  //     } catch (error) {
        
  //       return false;
  //     }
  // }

  // 获取总费用和已开票金额汇总
  const getAllFee = async () =>{
    try {
      await getApiData('U', 'U_getAllFee', {}).then((res) => {
        console.log('U_getAllFee-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          setTotalFee(res.data.d.allfee);
          // var list = [];
          // for(let item of res.data.d.list.notetype){
          //   list.push({value:item.name, label:item.name});
          // }
          // setNotetypeList(list);
        }
      });
      } catch (error) {
        
      }
  }

  const getOpList = async (params) =>{
    var list = [];
    try {
      await getApiData('U', 'U_caseResultList', {}).then((res) => {
        console.log('U_caseResultList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {

          list = res.data.d.info;
          console.log('--- list ---')
          console.log(list)
        }
      });
      } catch (error) {
        
      }
    return list;

  }


  return (
    <div>
    <ProList rowKey="id" 
      style={{display:showEnlist?"block":"none", minHeight:screenHeight*0.5}}
      headerTitle={"作品评选结果"}
        search={{
          filterType: 'light',
        }}
        // pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setChecklistloading(true);
          var tableData = [columsRow];
          // console.log('params----------------', params);
          const res = await getOpList(params);
          // console.log('res--------------', res);
          if(res){
            res.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k+1] = v;
              tableData[k+1]['content'] = {...v};
            })
          }
          // console.log('tableData--------------', tableData);
          setChecklistloading(false);
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{

          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },

        }} 
        
        />


      <div style={{float:"right", display:"flex", justifyContent:"center", alignItems:"center", width:130, height:50, backgroundColor:"rgb(152,100,201)", color:"white", borderRadius:10,  marginRight:40}}>
        <Space direction="vertical" size={0}>
          <span>金额总计：</span>
          <span>RMB {totalFee}</span>
        </Space>
      </div>
      <div style={{textAlign:"left", marginTop:20, marginLeft:15}}>
        温馨提示: 如您对费用细则有疑问，可联系您的赛事顾问确认。
      </div>

      </div>


);
}
export default App;