import axios from  'axios';
import AppConfig from "../app.config";

// import {message} from 'antd';

const baseURL = (val) =>{
    // console.log(val)
    const baseVal = {
        'development':AppConfig.INTER_BASE,//local 环境
        'production': AppConfig.INTER_BASE,//build 环境
    }
    return baseVal[val]
}

const http = axios.create({
    timeout:30000,
    baseURL:baseURL(process.env.NODE_ENV)
})

http.interceptors.request.use(config =>{
    // console.log('config---------------', config);

    config.headers['Content-Type'] = 'application/json';

    // let token = localStorage.getItem('token');
    // if(token) config.headers['Authorization'] = token;
    // if(token) config.headers['Authorization'] = `Bearer ${token}`;

    return config
}, err =>{
    return Promise.reject(err)
});

http.interceptors.response.use(res =>{
    if(res){
        // console.log('--------http-------------', res)
        return res
    }
},err =>{
    return Promise.reject(err)
})

export default http;
