import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout, Space,Divider,Image,InputNumber,Select,Radio } from 'antd';
import Icon,{ PlusOutlined,DeleteOutlined } from '@ant-design/icons';
import './index.css';
import UploadFile from '../../components/UploadFile';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import { red } from '@ant-design/colors';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const App =(props)=>{
  const {showAddtc, bEdit, rowInfo, closeAddtc, refreshList} = props;
  console.log('--------add rowInfo-----', rowInfo)
  const [form] = Form.useForm();
    // const [bEdit,setBEdit] = useState(false);

    const [percent, setpercent] = useState(0);
    const [percentShow,setPercentShow] = useState(true);
    const [imageUrl, setImageUrl] = useState("");
    // const [imageUrl, setImageUrl] = useState("https://kp-cloud-store.tos-cn-shanghai.volces.com/%E6%B8%B8%E6%88%8F%E5%BA%93%E4%B8%9A%E5%8A%A1/public/apks/62fb66acc287533b6d59284e_d63d19c9b3d9220b85dc23a5caebc9ab.png");
    const [notetypeList, setNotetypeList] = useState([]);
    const [invoiceform, setInvoiceform] = useState(1);
    const [maxFree, setMaxFree] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(showAddtc){
        getNotetype();
        getAllFee();
        if(!bEdit && rowInfo.id){
          rowInfo.invoiceform = parseInt(rowInfo.invoiceform);
          rowInfo.invoicetype = parseInt(rowInfo.invoicetype);
          rowInfo.voucher = parseInt(rowInfo.voucher);
          for (let key in rowInfo) {
            if(key == 'content')
              form.setFieldValue(key, rowInfo.contype)
            else
              form.setFieldValue(key, rowInfo[key])
          }
          setImageUrl(rowInfo.qualification);
          setInvoiceform(rowInfo.invoiceform);
        }
      }
      else{
        setpercent(0);
        setPercentShow(true);
        setImageUrl("");
        setNotetypeList([]);
        setInvoiceform(1);
        setLoading(false);
        form.resetFields();
      }
    }, [showAddtc]);

    
    const groupOptions = [
      {
          value: '1',
          label: '会展服务',
          children: [
          {
              value: '1-1',
              label: '会议服务',
          },
          {
              value: '1-2',
              label: '展览服务',
          }
          ],
      },
      {
          value: '2',
          label: '现代服务',
          children: [
          {
              value: '2-1',
              label: '咨询服务',
          },
          {
              value: '2-2',
              label: '调研服务',
          },
          {
              value: '2-3',
              label: '设计制作服务',
          },
          {
              value: '2-4',
              label: '信息技术服务',
          },
          {
              value: '2-5',
              label: '活动策划服务',
          },
          {
              value: '2-6',
              label: '奖项评选服务',
          },
          ],
      },
      ];

  // 设置文件地址
  const setFileUrl = (url) =>{
    setImageUrl(url);
    form.setFieldValue("qualification", url);
  }

  // 获取发票类型
  const getNotetype = async () =>{
    try {
      await getApiData('U', 'U_getSelectList', {ltype: ["notetype"]}).then((res) => {
        console.log('U_getSelectList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          var list = [];
          for(let item of res.data.d.list.notetype){
            list.push({value:item.name, label:item.name});
          }
          setNotetypeList(list);
        }
      });
      } catch (error) {
        
      }
  }

  
  // 获取总费用和已开票金额汇总
  const getAllFee = async () =>{
    try {
      await getApiData('U', 'U_getAllFee', {}).then((res) => {
        console.log('U_getAllFee-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          setMaxFree(res.data.d.allfee - res.data.d.notefee)
          // var list = [];
          // for(let item of res.data.d.list.notetype){
          //   list.push({value:item.name, label:item.name});
          // }
          // setNotetypeList(list);
        }
      });
      } catch (error) {
        
      }
  }


    const onFinish = async(value)=>{
        console.log(value);
        try {
          setLoading(true);
          await getApiData('U', 'U_createNote', value).then((res) => {
            setLoading(false);
            console.log('U_createNote-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              message.success("开票成功");
              closeAddtc();
              refreshList();
            }
          });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }

    }

    const uploadButton = (
      <div style={{width:40,height:40,color:"gray"}}>
        {<PlusOutlined />}
        <div
          style={{
            fontSize: 10,
          }}
        >
          点击上传营业执照
        </div>
      </div>
    );

  return (
    <div  id="box" className='container' style={{width:"70%", display:showAddtc?"block":"none"}} >
        <div style={{background:"white", overflow:'hidden'}}>
            {/* <Button type="text" style={{display:bEdit?"none":"block",float:"right",color:"#AAA"}} icon={<EditOutlined />} onClick={()=>{setBEdit(true)}}>编辑</Button> */}
            <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:6,offset:2}}
            style={{width:"100%"}}
            initialValues={{
              invoicetype: 1,
              invoiceform: 1,
              voucher: 2
            }}
        >
          
          <h4 style={{ textAlign:"left", fontSize:15}}>开票信息<span style={{color: "red", fontSize:13,float:"right"}}>*温馨提示：本页仅涉及需支付报名费及确认获奖企业填写</span></h4>
          <Divider className='add_divide' />
            <Form.Item
                label="资质证明文件："
                name="qualification"
                rules={[
                  {required: true, message: '请上传营业执照',},
                ]}
                style={{textAlign:"left"}}
            >
              <Space direction="horizontal" size={"large"}>
                {bEdit ? (
                  <UploadFile 
                    from = {"U"}
                    upButton = {<img src={[require("../../../img/uplicense.png")]} width={80} />}
                    uploadFileType = {".png, .jpg, .jpeg, .gif"}
                    uploadFileSize = {50*1024*1024}
                    setpercent = {setpercent}
                    setPercentShow = {setPercentShow}
                    setFileUrl = {setFileUrl}
                  />
                ) : "" }
                <div style={{height:100, marginBottom:10}}>
                  {imageUrl ? (
                    <Image
                      height={100}
                      src={imageUrl}
                    />
                  ) : "" }
                  <Button danger type='primary' style={{width:"100%", height:20, borderRadius:3, margin:"8px 0",display:bEdit && imageUrl?"block":"none"}} icon={<DeleteOutlined style={{fontSize:10}} />} onClick={()=>{setImageUrl("")}} />
                </div>
              </Space>
            </Form.Item>
            <Form.Item
                label="发票抬头"
                name="title"
                rules={[
                  {required: true, message: '请输入发票抬头',},
                ]}
            >
            <Input placeholder="发票抬头" bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item>
            <Form.Item
                label="税号(统一社会信用代码)："
                name="taxcode"
                rules={[
                  {required: true, pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: '请检查税号(统一社会信用代码)',},
                ]}
            >
            <Input placeholder="税号(统一社会信用代码)" bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item>
            <Form.Item
                label="开户银行"
                name="bank"
                rules={[
                  {required: true, message: '请输入开户银行',},
                ]}
            >
            <Input placeholder="开户银行" bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item>
            <Form.Item
                label="银行账号"
                name="bankaccount"
                rules={[
                  {required: true, pattern: new RegExp(/^[a-zA-Z0-9]+$/), message: '请检查银行账号',},
                ]}
            >
            <Input placeholder="账号" value={"账号"} bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item>
            <Form.Item
                label="地址"
                name="address"
                rules={[
                  {required: true, message: '请输入地址',},
                ]}
            >
            <TextArea placeholder="地址" autoSize={{minRows: 2,maxRows: 6,}} bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="电话"
                name="phone"
                rules={[
                  {required: true, pattern: new RegExp(/^[0-9]+$/), message: '请检查电话',},
                ]}
            >
            <Input placeholder="电话" bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item>
            <Form.Item
                label="开票内容："
                name="content"
                rules={[
                  {required: true, message: '请选择开票内容',},
                ]}
            >
                <Select
                    placeholder="请选择"
                    // onChange={onGenderChange}
                    // defaultValue={"1"}
                    // allowClear
                    // style={{width:"50%"}}
                    disabled={bEdit?false:true}
                    options={notetypeList}
                >
                </Select>
            </Form.Item>
            <Form.Item name="invoicetype"  
                label="发票类别：" 
                style={{textAlign:"left"}}
            >
                <Radio.Group disabled={bEdit?false:true}>
                {/* <Space size={30}> */}
                  <Radio value={1}>增值税普通发票</Radio>
                  <Radio value={2}>增值税专用发票</Radio>
                {/* </Space> */}
                </Radio.Group>

            </Form.Item>
            <Form.Item label="开票金额："  style={{textAlign:"left", marginBottom:0}}>
              <Space direction="horizontal">
                <Form.Item name="fee" style={{width:"80%"}}
                rules={[
                  {required: true, message: '请输入开票金额',},
                ]}
                >
                    <InputNumber min={1} max={maxFree}  style={{width:"100%"}} disabled={bEdit?false:true}/>
                </Form.Item>
                <Form.Item style={{display:bEdit?"block":"none"}}>
                <span style={{color:"red"}}>最大开票金额：{maxFree}</span>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item
                label="发票形式"
                name="invoiceform"
                style={{textAlign:"left"}}
            >
              <Radio.Group style={{width:"80%"}} onChange={(e)=>{setInvoiceform(e.target.value)}} disabled={bEdit?false:true}>
              <Space size={30}>
                <Radio value={1}>电子发票</Radio>
                {/* <Radio value={2}>纸质发票</Radio> */}
              </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                label="收件人"
                name="recipient" 
                style={{display:invoiceform==2?"block":"none"}}
                // rules={[
                //   {required: true, message: '请输入收件人'},
                // ]}

            >
            <Input placeholder="收件人" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="联系电话"
                name="recipientphone"
                style={{display:invoiceform==2?"block":"none"}}
                rules={[
                  {pattern: new RegExp(/^[0-9]+$/), message: '请检查联系电话',},
                ]}
            >
            <Input placeholder="联系电话" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="寄送地址"
                name="postaddress" 
                style={{display:invoiceform==2?"block":"none"}}
                // rules={[
                //   {required: true, message: '请输入寄送地址'},
                // ]}
            >
            <TextArea placeholder="寄送地址" autoSize={{minRows: 2,maxRows: 6,}} bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <h4 style={{paddingTop:30, textAlign:"left", fontSize:15}}>是否需要付费凭证(以下三选一)</h4>
            <Divider className='add_divide' />
            <Form.Item label="" name="voucher">
              <Radio.Group style={{width:"80%"}} disabled={bEdit?false:true}>
              <Space size={30}>
                <Radio value={1}>不需要</Radio>
                <Radio value={2}>付款说明</Radio>
                <Radio value={3}>合作协议</Radio>
              </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item >
            <Space size={50} style={{paddingBottom:20}}>
              {bEdit ? 
                (<Button type="primary" htmlType="submit" shape='round' loading={loading} style={{backgroundColor: "rgb(152,100,201)",borderColor: "#000000",marginTop:20, padding:"0 25px"}}>
                    提交
                </Button>)
              : ""}
            <Button type="primary"  shape='round' style={{backgroundColor: "#c1c1c1",borderColor: "#000000",marginTop:20, padding:"0 25px"}} onClick={()=>closeAddtc()}>
                {bEdit ? "取消" : "返回"}
            </Button>
            </Space>
            </Form.Item>
        </Form>
        <div style={{color: "rgba(0, 0, 0, 0.6)", fontSize:13,float:"right"}}>*温馨提示：本页仅涉及需支付报名费及确认获奖企业填写</div>
        </div>

    </div>
  )
}
export default App
