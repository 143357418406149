import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Row,Col,Checkbox } from 'antd';
import './styles.css';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
// import { useNavigate } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';
import Timebutton from "./timebutton.jsx"

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const Regist =(props)=>{
    const [form] = Form.useForm();
    const [bChecked,setChecked] = useState(false);
    const mobile = Form.useWatch('account', form);
    const [mobileStatus,setMobileStatus] = useState(false);
    const [credit,setCredit] = useState("");
    const [cid,setCid] = useState("");
    const [config,setConfig] = useState({footer:{}});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      getConfig();

    }, []);

    
    const getConfig = async()=>{
      try {
          return await getApiData('U', 'U_getConfig', {}).then((res) => {
            console.log('U_getConfig-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              setConfig(res.data.d.config)
            }
          });
        } catch (error) {
          
          return false;
        }
  }

    const validateMobile = (rule, value, callback) => {
      // console.log(rule)
      // console.log(value)
      // console.log(callback)
      setMobileStatus(false)
      if (value === '' || value==undefined) {
        callback(new Error('请输入邮箱或手机号'));
      }
      else{
        setMobileStatus(true)
        callback();
      }
    //   if (value === '' || value==undefined) {
    //       callback(new Error('请输入手机号'));
    //   } else {
    //      const reg = /^1[3-9]\d{9}$/; //正则校验
    //     if(reg.test(value)){
    //       setMobileStatus(true)
    //       callback();
    //     } else{
    //       callback('手机号格式错误');
    //     } 
    //  }
    };
    
    const onChangeCredit = (e) => {
      // console.log(e);
      console.log(e.target.value);
      form.setFieldValue("credit", e.target.value);
    };

    const onFinish = async(value)=>{
        // console.log(value);
        value.cid = cid;
        localStorage.removeItem('token_u');

        try {
            setLoading(true);
            await getApiData('U', 'U_reg', value).then((res) => {
              setLoading(false);
              console.log('U_reg-res-----------------', res);
              var flag = checkLogin('U', res.data)
              if (flag) {
                message.success("注册成功，请填写企业信息");
                localStorage.setItem('token_u',res.data.d.token);
                localStorageSet("UserInfo_u", {uid:res.data.d.uid, account:value.account});

                window.location.href=PathBASE+'#/register/info';
                // props.history.push({
                //     pathname: '/home',
                //   });
                // localStorage.setItem('token_u',res.data.data);

                
              }
            });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }
  return (
    <div className='loginbg'>
    <div  id="box" className='container' >
        <div style={{background:"white", borderRadius:18, overflow:'hidden', paddingTop:20}}>
            <img src={config.loginbanner?config.loginbanner:require("../../img/banner.png")} alt="" width="79%" />
            {/* <img src={[require("../../img/banner.png")]} alt="" width="100%" /> */}
            <Form form={form}
              className='loginForm'
              name="normal_login"
              // labelCol={{span: 5}}
              // wrapperCol={{span: 16}}
              onFinish={onFinish}
              layout="vertical"
              // onFinishFailed={this.onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="账号"
                name="account"
                className='normal_input'
                rules={[
                  { validator: validateMobile, trigger: 'blur' },
                  // {required: true, message: '请输入邮箱或手机号',},
                ]}
            >
            <Input placeholder="请输入邮箱或手机号" bordered={false} className="m_input" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="passwd"
                rules={[
                  {required: true,message: '密码必须6-30个字符',},
                  {max: 30,message: '密码最多30位',},
                  {min: 6,message: '密码至少6位',},
                  // {pattern:^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$,message: '密码必须包含字母和数字',},
                ]}
            >
            <Input.Password  placeholder="密码必须6-30个字符" bordered={false} />
            </Form.Item>
            <Form.Item
                label="验证码"
                name="credit"
                rules={[
                  {required: true, message: '请输入验证码',},
                ]}
            >
              <Input.Group >
                <Input bordered={false} placeholder="请输入验证码" onChange={onChangeCredit}
                  style={{
                    width: 'calc(100% - 200px)',
                    textAlign:"left"
                  }}
                />
                {/* <Button type="text" style={{float:"right"}}>发送验证码</Button> */}
                <Timebutton
                  mobile={mobile}
                  status={mobileStatus}
                  type="1"
                  setCid={setCid}
                />

              </Input.Group>
            </Form.Item>
            <Form.Item>
            <div>
            <Button type="primary" htmlType="submit" loading={loading} style={{width: 150,height:50,borderRadius: 30,backgroundColor: "rgb(152,100,201)",borderColor: "rgb(152,100,201)",fontSize: 20,marginTop:20}}>
                注册
            </Button>
            </div>
            <div style={{marginTop:20}}>已有账号，<a href="javascript:void(0)" onClick={()=>{window.location.href=PathBASE+'#/login';}} style={{color:"red"}}>去登录</a></div>
            </Form.Item>
        </Form>
        <div style={{height:80,marginBottom:30}}>
          <div style={{textAlign:"left", marginLeft:30, paddingTop:20, color:"#afa178", float:"left"}}>
            <div>TBI杰出品牌创新奖参赛指南 <a style={{color:"#afa178",textDecoration:"underline"}} href={config.cansaizhinan} target="_blank" >点击下载</a></div>
            <div style={{marginTop:10}}>参赛咨询：{config.footer==undefined?"":config.footer.phone}</div>
          </div>
          <div style={{paddingRight:30, float:"right", display:"flex", alignItems:"flex-end",height:80}}>
            <img src={config.clientlogo?config.clientlogo:[require("../../img/logo-05.png")]} alt="" width="120px" maxheight="120px" />
            {/* <img src={[require("../../img/logo-05.png")]} alt="" height="120px" /> */}
          </div>
        </div>
        </div>
        </div>
    </div>
  )
}
export default Regist
