import http from "./http";
// import CryptoJs from 'crypto-js';

export const getApiData = (sys, fun, data) =>{
    if(data.current){
        data.page = data.current;
        delete data.current;
    };
    if(data.pageSize){
        data.pagesize = data.pageSize;
        delete data.pageSize;
    };
    if(sys == 'A')
        data.token = localStorage.getItem('token');
    else if(sys == 'U')
        // data.token = "1597ec01fe317609f1b78266176a5517145";
        data.token = localStorage.getItem('token_u');
    return http ({
        method:'POST',
        url:'',
        data: {f:fun,p:data}
    })
}
