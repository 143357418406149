import { message } from 'antd';
// import { history, connect } from 'umi';
// import { stringify } from 'querystring';

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const checkLogin = (sys, data) => {

    if (typeof data == 'string') {
        data = JSON.parse(data);
    }
    if (data.c==1 && (data.ecode == -99995 || data.c == 10011 || data.c == 10012 || data.c == 10013 )) {
        message.error(data.m);
        if(sys == 'A'){
            localStorage.removeItem('token');
            localStorage.removeItem("UserInfo");
            window.location.href=PathBASE+'#/admin/login';
        }
        else if(sys == 'U'){
            localStorage.removeItem('token_u');
            localStorage.removeItem("UserInfo_u");
            window.location.href=PathBASE+'#/login';
        }
        // if (window.location.href.indexOf('user/login') == -1) {
            // history.replace({
            //     pathname: '/user/login',
            //     search: stringify({
            //         redirect: window.location.href,
            //     }),
            // });
        // }
        return false;
    } else if (data.c && data.c == 10008) {
        if(data.data == 8)
            window.location.href=PathBASE+'#/register/info';
        if(data.data == 9)
            window.location.href=PathBASE+'#/register/info';
    } else if (data.c === 0) {
        return true;
    } else if (data.c) {
        message.error(data.m);
        return false;
    }
}

export default checkLogin