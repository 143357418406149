import {getCatchPre} from './global.js';

export function getToken(){
    return localStorage.getItem("token");
}
export function setToken(token){
    localStorage.setItem("token",token);
}
export function clearToken(){
    localStorage.removeItem("token");
}
export function setCatch(key,value){
    localStorage.setItem(getCatchPre() + key,value);
}
export function getCatch(key){
    return localStorage.getItem(getCatchPre() + key) === null ? "" :  localStorage.getItem(getCatchPre() + key);
}
export function isLogined(){
    if(localStorage.getItem("token") !== "" && localStorage.getItem("token") !== null){
        return true;
    }
    return false;
}
export function setCookie(name,value)
{
var Days = 1;
var exp = new Date(); 
exp.setTime(exp.getTime() + Days*24*60*60*1000);
document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
}
//读取cookies
export function getCookie(name)
{
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
    if(arr===document.cookie.match(reg)){
        return unescape(arr[2]);
    }
    else{
        return null;
    }
}
//删除cookies
export function delCookie(name)
{
var exp = new Date();
exp.setTime(exp.getTime() - 1);
var cval=getCookie(name);
if(cval!=null) document.cookie= name + "="+cval+";expires="+exp.toGMTString();
}

export function doLogout(){
    setCatch("token","");
    setCatch("name","");
    window.location.href = './login';
}