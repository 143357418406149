import React from 'react';
import { Result  } from 'antd';
import './styles.css';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

const Register =(props)=>{
  const screenHeight = document.body.clientHeight;

  setTimeout(()=>logout(), 2000);

    //退出
    const logout = async () => {
      localStorage.setItem('token_u',"");
      localStorageSet("UserInfo_u", {});
      window.location.href='/#/login';
    }


  return (
    <div id='box_registerok' style={{background:"white", borderRadius:20}}>
    <Result
      icon={""}
      status="success"
      title="信息提交成功"
      subTitle="您的公司信息预计在72小时内完成审核，请耐心等待"
      extra={[
      ]}
    />
  </div>
  )
}
export default Register
