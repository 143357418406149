import React , { useState,useEffect }from 'react'
import { Button,  Form, Input, message} from 'antd';
import './styles.css';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
// import { useNavigate } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const screenHeight = document.body.clientHeight;
const screenWidth = document.body.clientWidth;
var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 
console.log('PathBASE - ', PathBASE);

const Login =(props)=>{
    const [form] = Form.useForm();
    const [bChecked,setChecked] = useState(false);
    const [config,setConfig] = useState({footer:{}});
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('token_u');
    var currentUserinfo = localStorageGet('UserInfo_u');
    console.log("token---------", token);
    console.log("currentUserinfo---------", currentUserinfo);
  
    if(token && currentUserinfo){
      if(currentUserinfo.companystatus != 1)
        window.location.href=PathBASE+'#/register/info';
      else
        window.location.href=PathBASE+'#/enter';
    }
  
    useEffect(() => {

      getConfig();

      var localLoginfo = localStorageGet('loginfo');
      // console.log('localLoginfo:----------- ', localLoginfo);
      if(localLoginfo){
        var loginfo_ = JSON.parse(decrypt(localLoginfo));
        // console.log('loginfo_--------------', loginfo_)
        form.setFieldValue('userName', loginfo_.userName);
        form.setFieldValue('password', loginfo_.password);
        setChecked(true);
      }
      else{
        setTimeout(()=>{
          form.setFieldValue('userName', "");
          form.setFieldValue('password', "");
          setChecked(false);

        }, 100)
      }
    }, []);
    
    const onChange = (e) => {
      setChecked(e.target.checked);
    };

    const getConfig = async()=>{
        try {
            return await getApiData('U', 'U_getConfig', {}).then((res) => {
              console.log('U_getConfig-res-----------------', res);
              var flag = checkLogin('U', res.data)
              if (flag) {
                setConfig(res.data.d.config)
              }
            });
          } catch (error) {
            
            return false;
          }
    }

    const onFinish = async(value)=>{
        // console.log(value);
        // console.log(bChecked);
        localStorage.removeItem('token_u');

        try {
            setLoading(true);
            return await getApiData('U', 'U_login', value).then((res) => {
              setLoading(false);
              console.log('U_login-res-----------------', res);
              var flag = checkLogin('U', res.data)
              if (flag) {
                // props.history.push({
                //     pathname: '/home',
                //   });
                localStorage.setItem('token_u',res.data.d.token);
                localStorageSet("UserInfo_u", {uid:res.data.d.uid, account:value.account, companystatus:res.data.d.companystatus});
                if(res.data.d.companystatus == 1){
                  window.location.href=PathBASE+'#/enter';
                }
                else
                  window.location.href=PathBASE+'#/register/info';
              }
            });
          } catch (error) {
            message.error("网络异常");
            setLoading(false);
            return false;
          }
    }

  return (
    <div className='loginbg'>
    <div  id="box" className='container' >
        <div style={{background:"white", borderRadius:18, overflow:'hidden', paddingTop:20}}>
            <img src={config.loginbanner?config.loginbanner:require("../../img/banner.png")} alt="" width="79%" />
            {/* <img src={[require("../../img/banner.png")]} alt="" width="100%" /> */}
            <Form form={form}
            className='loginForm'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="vertical"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="账号"
                name="account"
                className='normal_input'
                rules={[
                  {required: true, message: '请输入邮箱或手机号',},
                  // {pattern: /^1\d{10}$/, message: '手机号格式错误'},

                  // {max: 12,message: '用户名最多12位',},
                  // {min: 3,message: '用户名至少3位',},
                  // {pattern:/^[a-zA-Z0-9_]+$/,message: '用户名必须是英文、字母或下划线',},

                    // {
                    // required: true,
                    // message: '请输入您的手机号！',
                    // },
                ]}
            >
            <Input placeholder="请输入邮箱或手机号" bordered={false} className="m_input" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="passwd"
                rules={[
                  {required: true,message: '密码必须6-30个字符',},
                  {max: 30,message: '密码最多30位',},
                  {min: 6,message: '密码至少6位',},
                  // {pattern:^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$,message: '密码必须包含字母和数字',},
                ]}
            >
            <Input.Password  placeholder="密码必须6-30个字符" bordered={false} />
            </Form.Item>
            <Form.Item>
            <a href="javascript:void(0)" onClick={()=>{window.location.href=PathBASE+'#/login/forgetpwd';}} style={{float:"right",color: "rgba(0, 0, 0, 0.5)"}}>忘记密码</a>
            </Form.Item>
            <Form.Item>
            <div>
            <Button type="primary" htmlType="submit" loading={loading} style={{width: 150,height:50,borderRadius: 30,backgroundColor: "rgb(152,100,201)",borderColor: "rgb(152,100,201)",fontSize: 20,marginTop:-10}}>
                登录
            </Button>
            </div>
            <div style={{marginTop:20}}>还没有账号，<a href="javascript:void(0)" onClick={()=>{window.location.href=PathBASE+'#/register';}} style={{color:"red"}}>去注册</a></div>
            </Form.Item>
        </Form>
        <div style={{height:120,marginBottom:30}}>
          <div style={{textAlign:"left", marginLeft:30, paddingTop:20, color:"#afa178", float:"left"}}>
            <div>TBI杰出品牌创新奖参赛指南 <a style={{color:"#afa178",textDecoration:"underline"}} href={config.cansaizhinan} target="_blank" >点击下载</a></div>
            <div>TBI杰出品牌创新奖参赛报名+作品描述模板 <a style={{color:"#afa178",textDecoration:"underline"}} href={config.baomingmoban} target="_blank">点击下载</a></div>
            {/* <div>TopDigital创新营销奖参赛作品报送指南 <a style={{color:"#afa178",textDecoration:"underline"}} href={config.baosongzhinan} target="_blank">点击下载</a></div> */}
            <div style={{marginTop:10}}>参赛咨询：{config.footer==undefined?"":config.footer.phone}</div>
          </div>
          <div style={{paddingRight:30, float:"right", display:"flex", alignItems:"flex-end",height:120}}>
            <img src={config.clientlogo?config.clientlogo:[require("../../img/logo-05.png")]} alt="" width="120px" maxheight="120px" />
            {/* <img src={} alt="" height="120px" /> */}
          </div>
        </div>
        </div>
        </div>
    </div>
  )
}
export default Login
