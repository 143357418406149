
import CryptoJS from 'crypto-js';
import ExportJsonExcel from 'js-export-excel';


export const buttonBlur = (event) =>{
    // 点击后鼠标移开恢复按钮默认样式(如果按钮没有加icon图标的话，target.nodeName == "I"可以去掉)
    let target = event.target;
    if(target.nodeName === "I" || target.nodeName === "SPAN"){
        target = event.target.parentNode;
    }
    target.blur();
}

export const customTabinkbar = () =>{
    // setTimeout(() => {
    //     console.log(document.getElementsByClassName("ant-tabs-tab-active"))
    //     var tabactive = document.getElementsByClassName("ant-tabs-tab-active");
    //     var tabWidth = tabactive[0].offsetWidth;
    //     var tabLeft = tabactive[0].offsetLeft;
    //     console.log('------------111111----------', tabWidth, tabLeft);
  
    //     var bar = document.getElementsByClassName("ant-tabs-ink-bar");
    //     console.log(bar);
    //     console.log('bar--------', bar[0].offsetWidth, bar[0].offsetLeft);
    //     // console.log('bar2--------', bar[0].offsetWidth, bar[0].offsetLeft);
    //     // console.log('------------',(bar[0].offsetLeft+curwidth*0.25)+"px");
    //     bar[0].style.left = (tabLeft+tabWidth*0.2)+"px";
    //     setTimeout(() => {
    //         bar[0].style.width = tabWidth*0.6+"px";
    //     }, 10);
    //   }, 100);
  
}

// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse("3k3dfeu890ijdufk");
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("32edfh8963jfyemc");

export function getCatchPre(){
  return 'JUDGE_';
}

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
 export function encrypt(data) {
  if (typeof data === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      data = JSON.stringify(data);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }
  const dataHex = CryptoJS.enc.Utf8.parse(data);
  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
 export function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

//设置缓存
export const localStorageSet = (name, data) => {
  const obj = {
      data,
      expire: new Date().getTime() + 1000 * 60 * 60 * 72
  };
  localStorage.setItem(name, JSON.stringify(obj));
};

//读取缓存
export const localStorageGet = name => {
  const storage = localStorage.getItem(name);
  const time = new Date().getTime();
  let result = null;
  if (storage) {
      const obj = JSON.parse(storage);
      if (time < obj.expire) {
          result = obj.data;
      } else {
          localStorage.removeItem(name);
      }
  }
  return result;
};

// 控制手机菜单
export const postMessage = (id,code,msg) => {
  // if(statue){
    let iFrame = document.getElementById(id)
    let token =  localStorage.getItem("token");
    // console.log(code)
    // console.log(msg)
    // if(appId){
    //   iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token,appId:appId},'*');
    // }else{
      iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token},'*');
      // iFrame.contentWindow.postMessage({statue:code,msg:msg,token:token});
    // }
  // }else{
  //   message.warn('视频流未加载完成！')
  // }
}

// 生成sessionid
export const Sessionid = (len=4) => {
  var num = "";
  for (var i = 0; i < len; i++) //指定位数的随机数，用以加在时间戳后面。
  {
      num += Math.floor(Math.random() * 10);
  }
  num = new Date().getTime() + num;  //时间戳，用来生成用户编号。
  return num;
}

export const waitTimePromise = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time) => {
  await waitTimePromise(time);
};

// 下载表格数据到excel表格
export const downloadExcel = (filename, columns, data, key, selectedkeys) => {
  console.log('columns ------------ ', columns);
  var valueEnum = {};
  for(let item of columns){
    if(item.valueType==="select" && item.valueEnum){
      valueEnum[item.dataIndex] = item.valueEnum;
    }
  }
  console.log('valueEnum ------------ ', valueEnum);

    var option={};
    var col =[] 
    let dataTable = [];
    if (data) {
      for (let m in data) {
        if(data){
          let obj = {};
          let len = columns.length;
          for(var i=0; i<len; i++){
            obj[columns[i].title] = valueEnum[columns[i].dataIndex] ? (
              valueEnum[columns[i].dataIndex][data[m][columns[i].dataIndex]] ? 
              valueEnum[columns[i].dataIndex][data[m][columns[i].dataIndex]].text : 
              data[m][columns[i].dataIndex]
              ) : data[m][columns[i].dataIndex];
            if(m === 0)
              col.push(columns[i].title); 
          }
          dataTable.push(obj);
        }
      }
    }

    option.fileName = filename;
    option.datas=[
      {
        sheetHeader:col,
        sheetData:dataTable,
        type: 'xlsx',// 设置生成Excel的版本为.xlsx格式
        // sheetName:'Worksheet',
        // sheetFilter:col,
      }
    ];
    var toExcel = new ExportJsonExcel(option); 
    toExcel.saveExcel();        
};


export const RandomKey = () => {
  var num =  Math.floor(Math.random() * 1000) + "" + Math.floor(Math.random() * 1000);
  num = new Date().getTime() + num;  //时间戳，用来生成用户编号。
  return num;
}