import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Image, Space } from 'antd';
import './styles.css';
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
// import { useNavigate } from 'react-router-dom';
// import CryptoJS from 'crypto-js';
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';
import UploadFile from '../components/UploadFile';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const Login =(props)=>{
    const [form] = Form.useForm();
    const [cid,setCid] = useState("");
    const [config,setConfig] = useState({footer:{}});
    const [loading, setLoading] = useState(false);
    const [percent, setpercent] = useState(50);
    const [percentShow,setPercentShow] = useState(true);
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
      getConfig();

      try {
        getApiData('U', 'U_mycompany', {}).then((res) => {
          console.log('U_mycompany-res-----------------', res);
          var flag = checkLogin('U', res.data)
          if (flag) {
            if(res.data.d.companyinfo.cid){
              setCid(res.data.d.companyinfo.cid);
              setImageUrl(res.data.d.companyinfo.logo);
              for(let key in res.data.d.companyinfo)
                form.setFieldValue(key, res.data.d.companyinfo[key]);
            }
          }
        });
      } catch (error) {
        
      }
    }, []);

    
    const validateMobile = (rule, value, callback) => {
      if (value === '' || value==undefined) {
          callback(new Error('请输入手机号'));
      } else {
         const reg = /^1[3-9]\d{9}$/; //正则校验
        if(reg.test(value)){
          callback();
        } else{
          callback('手机号格式错误');
        } 
     }
    };
    
    
    const getConfig = async()=>{
      try {
          return await getApiData('U', 'U_getConfig', {}).then((res) => {
            console.log('U_getConfig-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              setConfig(res.data.d.config)
            }
          });
        } catch (error) {
          
          return false;
        }
  }

    //退出
    const logout = async () => {
      localStorage.setItem('token_u',"");
      localStorageSet("UserInfo_u", {});
      window.location.href=PathBASE+'#/login';
    }

    const onFinish = async(value)=>{
        console.log(value);
        if(!value.logo){
          message.error("请上传公司logo");
          return false;
        }
        value.logo = imageUrl;

        var fun = "U_createCompany";
        if(cid){
          value.cid = cid;
          fun = "U_editCompany";
        }

        try {
            setLoading(true);
            return await getApiData('U', fun, value).then((res) => {
              setLoading(false);
              console.log(fun+'-res-----------------', res);
              var flag = checkLogin('U', res.data)
              if (flag) {
                // if(fun == "U_createCompany")
                  message.success({
                    content: "提交成功，正在审核中，预计1-3个工作日完成审核，或联系您的赛事顾问加快审核！",
                    // content: "提交成功，正在审核中，通过后将以邮件形式通知...",
                    duration: 3,
                    style: {marginTop:"35vh"}
                  });
                // else
                //   message.warn({
                //     content: "您的公司暂未通过审核，请稍后再试或联系您的赛事顾问",
                //     // content: "请勿重复提交，如有疑问请联系："+(config.footer==undefined?"":config.footer.phone),
                //     duration: 3,
                //     style: {marginTop:"35vh"}
                //   });
                setTimeout(()=>logout(), 3500);
              }
            });
          } catch (error) {
            console.log(error);
            message.error("网络异常");
            setLoading(false);
            return false;
          }

    }
  return (
    <div className='loginbg'>
    <div  id="box" className='container'>
        <div style={{background:"white", borderRadius:18, overflow:'hidden', paddingTop:20}}>
            <img src={config.loginbanner?config.loginbanner:require("../../img/banner.png")} alt="" width="79%" />
            <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:6,offset:2}}
        >
          <h4 style={{paddingBottom: 15, textAlign:"left", fontSize:15}}>参赛公司信息</h4>
            <Form.Item
                label="公司中文全称"
                name="cname"
                rules={[
                  {required: true, message: '请输入公司中文全称',},
                ]}
            >
            <Input placeholder="公司中文全称" />
            </Form.Item>
            <Form.Item
                label="公司中文简称"
                name="sname"
                rules={[
                  {required: true, message: '请输入公司中文简称',},
                ]}
            >
            <Input placeholder="公司中文简称" />
            </Form.Item>
            {/* <Form.Item
                label="公司-英文名称"
                name="ename"
                rules={[
                  {required: true, message: '请输入公司-英文名称',},
                ]}
            >
            <Input placeholder="公司-英文名称" />
            </Form.Item> */}
            <Form.Item
                label="公司logo："
                name="logo"
                style={{textAlign:"left"}}
                rules={[
                  {required: true, message: '请上传公司logo',},
                ]}
                // extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:20,width:"100%",textAlign:"left"}}>*如需更换logo或展示多个logo，仅支持获奖作品中的关联企业/品牌；多个logo需自行编辑后上传。logo仅用于证书制作</div>}
            >
              <Space direction="horizontal" size={"large"}>
                <UploadFile 
                  from = {"U"}
                  upButton = {<img src={[require("../../img/uplogo.png")]} width={80} />}
                  uploadFileType = {".png, .jpg, .jpeg, .gif"}
                  uploadFileSize = {50*1024*1024}
                  setpercent = {setpercent}
                  setPercentShow = {setPercentShow}
                  setFileUrl = {setImageUrl}
                />
                <div style={{height:90}}>
                  {imageUrl ? (
                    <Image
                      height={80}
                      src={imageUrl}
                    />
                  ) : "" }
                </div>
              </Space>
            </Form.Item>
          <h4 style={{padding: "20px 0 15px", textAlign:"left", fontSize:15}}>奖项联系人信息</h4>
            <Form.Item
                label="姓名"
                name="contact"
                rules={[
                  {required: true, message: '请输入姓名',},
                ]}
            >
            <Input placeholder="姓名" />
            </Form.Item>
            <Form.Item
                label="职位"
                name="position"
                rules={[
                  {required: true, message: '请输入职位',},
                ]}
            >
            <Input placeholder="职位" />
            </Form.Item>
            <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  // { validator: validateMobile, trigger: 'blur' },
                  {required: true, message: '请输入手机号',},
                ]}
            >
            <Input placeholder="手机号" />
            </Form.Item>
            {/* <Form.Item
                label="电子邮箱"
                name="email"
                rules={[
                  {required: true, message: '请输入手机号',},
                ]}
            >
            <Input placeholder="email" />
            </Form.Item> */}
            <Form.Item
                label="联系地址"
                name="address"
                rules={[
                  {required: true, message: '请输入联系地址',},
                ]}
            >
            <TextArea rows={4} />
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  {required: true, message: '请输入邮箱',},
                ]}
            >
            <Input placeholder="邮箱" />
            </Form.Item>
            <Form.Item>
            <Space size={50} style={{paddingBottom:20}}>
            <Button type="primary" htmlType="submit" shape='round' loading={loading} style={{backgroundColor: "rgb(152,100,201)",borderColor: "#000000",marginTop:20, padding:"0 25px"}}>
                提交
            </Button>
            <Button type="primary"  shape='round' style={{backgroundColor: "#c1c1c1",borderColor: "#000000",marginTop:20, padding:"0 25px"}} onClick={logout}>
                取消
            </Button>
            </Space>
            </Form.Item>
        </Form>
        </div>
      </div>

    </div>
  )
}
export default Login
