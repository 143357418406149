import React , { useState,useEffect }from 'react'
import { Button,  Form, Input,message,Layout,Divider, Space,Image } from 'antd';
import Icon,{EditOutlined  } from '@ant-design/icons';
import './index.css';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import UploadFile from '../../components/UploadFile';

// const navigate = useNavigate();
  // navigate(-1)//适用于返回上级页面
  // navigate('/router');//也可直接加路径

const { Header, Content, Footer, Sider } = Layout;
const { TextArea } = Input;

const App =(props)=>{
    const [form] = Form.useForm();
    const [bEdit,setBEdit] = useState(false);
    const [companyInfo,setCompanyInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [percent, setpercent] = useState(50);
    const [percentShow,setPercentShow] = useState(true);
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
      try {
        getApiData('U', 'U_mycompany', {}).then((res) => {
        console.log('U_mycompany-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
            setCompanyInfo(res.data.d.companyinfo)
            setImageUrl(res.data.d.companyinfo.logo);
            for(let k in res.data.d.companyinfo){
              form.setFieldValue(k, res.data.d.companyinfo[k])
            }
        }
        });
      } catch (error) {
          console.log('catch-error2 ---------- ', error)
          // return false;
      }
    }, []);
    
    // 提交
    const onFinish = async(value)=>{
        console.log(value);
        if(!value.logo){
          message.error("请上传公司logo");
          return false;
        }
        value.logo = imageUrl;
        value.cid = companyInfo.cid;
        try {
          setLoading(true);
          await getApiData('U', 'U_editCompany', value).then((res) => {
            setLoading(false);
            console.log('U_editCompany-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
                message.success("修改成功");
            }
          });
        } catch (error) {
            message.error("网络异常");
            setLoading(false);
            console.log('catch-error2 ---------- ', error)
            return false;
        }
    }

    // 取消
    const onCancel = async()=>{
      for(let k in companyInfo){
        form.setFieldValue(k,companyInfo[k]);
        setBEdit(false);
      }
    }

  return (
    <div  id="box" className='container' style={{width:"70%"}} >
        <div style={{background:"white", borderRadius:18, overflow:'hidden'}}>
            <Button type="text" style={{display:bEdit?"none":"block",float:"right",color:"#AAA"}} icon={<EditOutlined />} onClick={()=>{setBEdit(true)}}>编辑</Button>
            <Form form={form}
            className='loginForm reginfo'
            name="normal_login"
            // labelCol={{span: 5}}
            // wrapperCol={{span: 16}}
            onFinish={onFinish}
            layout="horizontal"
            // onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{span:5,offset:2}}
        >
          <h4 style={{textAlign:"left", fontSize:15}}>注册公司信息</h4>
          <Divider className='add_divide' />
            <Form.Item
                label="公司中文全称"
                name="cname"
                rules={[
                  {required: true, message: '请输入公司中文全称',},
                ]}
            >
            <Input placeholder="公司中文全称" bordered={bEdit?true:false} disabled />
            </Form.Item>
            <Form.Item
                label="公司中文简称"
                name="sname"
                rules={[
                  {required: true, message: '请输入公司中文简称',},
                ]}
            >
            <Input placeholder="公司中文简称" bordered={bEdit?true:false} disabled />
            </Form.Item>
            {/* <Form.Item
                label="公司-英文名称(如有)"
                name="ename"
                rules={[
                  {required: true, message: '请输入公司-英文名称',},
                ]}
            >
            <Input placeholder="公司-英文名称" value={"公司-英文名称"} bordered={bEdit?true:false} disabled={bEdit?false:true} />
            </Form.Item> */}
            <Form.Item
                label="公司logo："
                name="logo"
                style={{textAlign:"left"}}
                rules={[
                  {required: true, message: '请上传公司logo',},
                ]}
                // extra={<div style={{color:'#d92a05',fontSize:'12px',lineHeight: '20px',paddingTop:20,width:"100%",textAlign:"left"}}>*如需更换logo或展示多个logo，仅支持获奖作品中的关联企业/品牌；多个logo需自行编辑后上传。logo仅用于证书制作</div>}
            >
              <Space direction="horizontal" size={"large"}>
              {bEdit ? (
                  <UploadFile 
                    from = {"U"}
                    upButton = {<img src={[require("../../../img/uplogo.png")]} width={80} />}
                    uploadFileType = {".png, .jpg, .jpeg, .gif"}
                    uploadFileSize = {50*1024*1024}
                    setpercent = {setpercent}
                    setPercentShow = {setPercentShow}
                    setFileUrl = {setImageUrl}
                  />
                ) : "" }
                <div style={{height:90}}>
                  {imageUrl ? (
                    <Image
                      height={80}
                      src={imageUrl}
                    />
                  ) : "" }
                </div>
              </Space>
            </Form.Item>
          <h4 style={{paddingTop: 30, textAlign:"left", fontSize:15}}>联系人信息<span style={{fontSize:13,color:"red",float:"right"}}>该联络人注册资料可用于接收奖杯&证书、发票</span></h4>
          <Divider className='add_divide' />
            <Form.Item
                label="姓名"
                name="contact"
                rules={[
                  {required: true, message: '请输入姓名',},
                ]}
            >
            <Input placeholder="姓名" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="职位"
                name="position"
                rules={[
                  {required: true, message: '请输入职位',},
                ]}
            >
            <Input placeholder="职位" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="手机号"
                name="phone"
                rules={[
                  {required: true, message: '请输入手机号',},
                ]}
            >
            <Input placeholder="手机号" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="联系地址"
                name="address"
                rules={[
                  {required: true, message: '请输入联系地址',},
                ]}
            >
            <TextArea autoSize={{minRows: 2,maxRows: 6,}} value={"联系地址"} bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  {required: true, message: '请输入邮箱',},
                ]}
            >
            <Input placeholder="邮箱" bordered={bEdit?true:false} disabled={bEdit?false:true}  />
            </Form.Item>
            <Form.Item style={{display:bEdit?"block":"none"}}>
            <Space size={50} style={{paddingBottom:20}}>
            <Button type="primary" htmlType="submit" shape='round' loading={loading} style={{backgroundColor: "rgb(152,100,201)",borderColor: "#000000",marginTop:20, padding:"0 25px"}}>
                提交
            </Button>
            <Button type="primary"  shape='round' style={{backgroundColor: "#c1c1c1",borderColor: "#000000",marginTop:20, padding:"0 25px"}} onClick={onCancel}>
                取消
            </Button>
            </Space>
            </Form.Item>
            <h4 style={{paddingTop: 30, textAlign:"left", fontSize:15}}>赛事顾问</h4>
            <Divider className='add_divide' />
            <Form.Item
                label={companyInfo.follow}
                className="comp-follow"
            >
            <div style={{textAlign:"left"}}>
              <span>{companyInfo.follow_phone}</span>
              <span style={{marginLeft:"2em"}}>{companyInfo.follow_email}</span>
            </div>
            </Form.Item>
        </Form>
        </div>

    </div>
  )
}
export default App
