import React, { useState,useEffect }  from 'react';
// import Icon, {UserOutlined,DownOutlined} from '@ant-design/icons';
import { Layout, Menu,Space } from 'antd';
import {useNavigate} from "react-router-dom"
import './index.css';
import { useLocation } from 'react-router'
import {getApiData} from "../../request/api";
import checkLogin from '../../request/checkLogin.js'
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../utils/global.js';

import Enroll from '../enter/enroll/index.jsx';
import Enlist from '../enter/enlist/index.jsx';
import Queryt from '../enter/queryt/index.jsx';
import Invoice from '../enter/invoice/index.jsx';
import Overprint from '../enter/overprint/index.jsx';
// import Addtc from '../enter/addtc/index.jsx';


// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

// const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const scrheight = document.body.clientHeight;
// console.log(scrheight)
// 菜单
const menuOption = {
  '/enter/enroll': {label:'企业注册报名', component:<Enroll />, show:true},
  '/enter/enlist': {label:'参赛作品提交', component:<Enlist />, show:true},
  // '/enter/addtc': {label:'创建作品', component:<Addtc />, show:false},
  '/enter/queryt': {label:'结果及费用查询', component:<Queryt />, show:true},
  '/enter/invoice': {label:'开票信息', component:<Invoice />, show:true},
  '/enter/overprint': {label:'加印管理', component:<Overprint />, show:true},
};

const menuList = [];
for(let key in menuOption){
	// console.log(key,menuOption[key])
  if(menuOption[key].show)
    menuList.push({key:key, label:menuOption[key].label})
}

const Admin =(props)=>{

  const navigate = useNavigate();
  const [curMenu, setCurMenu] = useState("/enter/enlist");
  const [config,setConfig] = useState({footer:{}});

  const token = localStorage.getItem('token_u');
  var currentUserinfo = localStorageGet('UserInfo_u');
  // console.log("currentUserinfo---------", currentUserinfo);

  if(!token || !currentUserinfo)
    window.location.href=PathBASE+'#/login';
  else if(currentUserinfo.companystatus != 1)
    window.location.href=PathBASE+'#/register/info';


  // const currentUserinfo = JSON.parse(decrypt(UserInfo));
  // // console.log("currentUserinfo---------", currentUserinfo);
  // if(currentUserinfo.menuPerm.lengh == 0)
  //   window.location.href='/#/login';

  const location = useLocation();
  // console.log('location - ', location)

  useEffect(() => {
    if(token && currentUserinfo)
      getConfig();

    if(menuOption[location.pathname])
      setCurMenu(location.pathname);

  }, [location.pathname]);

    
  const getConfig = async()=>{
    try {
        return await getApiData('U', 'U_getConfig', {}).then((res) => {
          console.log('U_getConfig-res-----------------', res);
          var flag = checkLogin('U', res.data)
          if (flag) {
            setConfig(res.data.d.config)
          }
        });
      } catch (error) {
        
        return false;
      }
  }


    //退出
    const logout = async () => {
      localStorage.setItem('token_u',"");
      localStorageSet("UserInfo_u", {});
      window.location.href=PathBASE+'#/login';
    }

  const onClickMenu = ({ key }) => {
    setCurMenu(key);
  };

  return (
    <Layout className="layout">
    <Header>
      <div style={{float:'left', width:"85%"}}>
      <div className="logo" ><img src={config.clientlogo?config.clientlogo:[require("../../img/tbilogo.png")]} alt="" width="120px" /></div>
      <Menu
        theme="light"
        mode="horizontal"
        style={{color:"rgb(152,100,201)"}}
        // defaultSelectedKeys={['enlist']}
        selectedKeys={[curMenu]}
        items={menuList}
        onClick={onClickMenu}
      />
      </div>
      {/* <div style={{float:'right'}}>
        {"欢迎回来！"+currentUserinfo.account}
      </div> */}
    </Header>
    <Content
      style={{
        padding: '30px 50px 5px',
        backgroundColor: 'rgb(152,100,201)',
        minHeight: scrheight*0.5
      }}
    >
      {/* <div className="top-nav-img" ><img src={[require("../../img/logo-04.png")]} alt=""  /></div> */}

      <div style={{textAlign:"center",width: "100%",height:"150px",opacity:"0.4",marginTop:"-20px",backgroundPositionY:"top",backgroundSize:"20%"}}>
        <img src={[require("../../img/tbilogo2.png")]} alt="" style={{height:"90%"}} />
      </div>
      <div class="user-top-1" style={{float:"right", top:-100, position:"relative"}}>
        <Space direction="horizontal">
        <div class="user-img">
            <img class="plupload" src={[require("../../img/avatar.png")]} 
              style={{width:56,height:56,borderRadius:5,clear:"both",margin:"auto",marginTop:4}}/>
        </div>
        <div style={{textAlign:"left", color:"white"}}>
        <Space direction="vertical" size={1}>
          <div>欢迎回来</div>
          <div>Welcome to TBI</div>
          <div style={{fontSize:18}}>{currentUserinfo.account} <a href="javascript:none" onClick={logout}  style={{fontSize:10,color:"white"}}>退出账号</a></div>
        </Space>
        </div>
        </Space>
      </div>

      <div className="site-layout-content" style={{minHeight:scrheight-250}}>

      { menuOption[curMenu].component}

    </div>
    </Content>
    <Footer
      style={{
        textAlign: 'center',
        lineHeight: 1,
        backgroundColor: 'rgb(152,100,201)',
        paddingBottom: 20
      }}
    >
      <div style={{backgroundColor: "white", padding:"14px 0 1px 0"}}>
        <p>联系方式：{config.footer.phone}</p>
        <p>{config.footer.email}</p>
        <p>{config.footer.copyright}</p>
      </div>
    </Footer>
  </Layout>
  )
}

export default Admin