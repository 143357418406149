import React, { useEffect,useState,} from 'react';
import {Form,Layout,Select,Table, } from 'antd'
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'
import { useLocation } from 'react-router-dom'; 
import {getCatch,doLogout} from '../../../utils/auth';
import {RandomKey} from '../../../utils/global';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css';  
import 'slick-carousel/slick/slick-theme.css';  
import './index.css'
import { encrypt,decrypt,localStorageSet,localStorageGet } from '../../../utils/global.js';

const { Header, Content, Footer } = Layout;

var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const App = (props) => {
  
  const token = localStorage.getItem('token_u');
  var currentUserinfo = localStorageGet('UserInfo_u');
  // console.log("currentUserinfo---------", currentUserinfo);

  if(!token || !currentUserinfo)
    window.location.href=PathBASE+'#/login';


    const location = useLocation(); 
    const LOGINNAME = getCatch("name");
    const scrheight = document.body.clientHeight;
  
    const [CASEINFO, setCaseinfoElements] = useState('');  
    const [PICLIST, setPiclistElements] = useState(''); 
    const [STANDARD, setStandardElements] = useState(''); 
    const [PICBTNLIST, setPicbtnlistElements] = useState(''); 
    const [config,setConfig] = useState({footer:{}});
    const [PDFLIST, setPdflistElements] = useState(''); 

    const settings = {  
          dots: true,//是否显示小圆点索引
          autoplay: true,//是否自动播放
          infinite: true,//是否无限循环
          autoplaySpeed: 3000,//自动播放的时间
          fade: true,//是否采用淡入淡出的效果
          slidesToShow: 1,
          slidesToScroll: 1 
    }; 
  
    let attach_piclist = [];

    let data = [
      // {title:"创新性  (40%)",content:"述案例描述案例描述案例描"},
      // {title:"创新性  (30%)",content:"述案例描述案例描述案例描"},
    ];
  
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        width:'20%',
      },
      {
        title: 'Content',
        dataIndex: 'content',
        align:"left"
      }
    ];
      
  
    useEffect(() => {
        getConfig();
        let params = new URLSearchParams(location.search);
        let id = params.get("id");
        getCaseDetail(id);
      }, []);

    //退出
    const logout = async () => {
      localStorage.setItem('token_u',"");
      localStorageSet("UserInfo_u", {});
      window.location.href=PathBASE+'#/login';
    }

    // 获取页脚信息
    const getConfig = async()=>{
      try {
          return await getApiData('U', 'U_getConfig', {}).then((res) => {
            console.log('U_getConfig-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              setConfig(res.data.d.config)
            }
          });
        } catch (error) {
          
          return false;
        }
    }

    // 打开附件PDF或图片在线预览
    const view = async(url, type)=>{
      try {
          return await getApiData('U', 'U_viewAttach', {"url":url,"type":type}).then((res) => {
            console.log('U_viewAttach-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
              window.open(res.data.d.url);
            }
          });
        } catch (error) {
          
          return false;
        }
    }
    
    //获取案例详情
    const getCaseDetail = async (caseid) => {
        try {
            await getApiData('U', 'U_caseDetail', {caseid:caseid}).then((res) => {
            console.log('U_caseDetail-res-----------------', res);
            var flag = checkLogin('U', res.data)
            if (flag) {
                let ele = [];
                let datainfo = res.data.d.info;
                ele.push(<div key={RandomKey()} id="upload" className="sanjiao-img" style={{margin:"0",backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"contain",textAlign:"center",width:"100%"}}></div>);
                ele.push(<div key={RandomKey()} style={{border:"1px solid rgb(152,100,201)",paddingLeft:"10px",textAlign:"left",width:"100%",background:"rgb(152,100,201)",marginTop:10}}>
                  <div style={{color:"#fff",fontSize:"30px",fontWeight:"bold",marginTop:"10px"}}>{datainfo.name}</div>
                  <div style={{color:"#fff",fontSize:"20px"}}>参赛单位: {datainfo.showcomp}</div>
                  <div style={{color:"#fff",marginBottom:"10px"}}>{datainfo.symbol}</div>
                  </div>);

                //基本信息
                let editors = [];
                ele.push(<div key={RandomKey()} className="hh" style={{marginBottom:"20px"}}>基本信息</div>);
                let showinfo = "";
                let uploadpic = "";
                let info = datainfo.detail.base;
                for(let i = 0;i < info.length;i++){
                  if(info[i].type === 'editor'){
                    ele.push(<div key={RandomKey()} style={{fontSize:"14px",fontWeight:"bold",textAlign:"left",marginTop:"20px"}}>{info[i].title}</div>);
                    ele.push(<div key={RandomKey()} id={info[i].name} name={info[i].name} className='offerContent'></div>);
                    editors.push({"id":info[i].name,"value":info[i].myvalue});
                  } else if(info[i].type === 'upload') {
                    if(info[i].name == 'banner'){
                      uploadpic = info[i].myvalue;
                      continue;
                    } else if(info[i].name == 'showcomplogo'){
                      continue;
                    } else {
                      ele.push(<div key={RandomKey()} style={{fontSize:"14px",fontWeight:"bold",textAlign:"left",marginTop:"20px"}}>{info[i].title}</div>);
                      if(info[i].myvalue)
                        ele.push(<div key={RandomKey()} id={info[i].name} name={info[i].name} className='offerContent'><img src={info[i].myvalue} style="width:100%; " /></div>);
                    }
                  } else {
                    switch(info[i].type){
                      case 'text':
                        showinfo = info[i].myvalue;
                        break;
                      case 'range':
                          try{
                            showinfo = info[i].myvalue[0].substr(0,10) + " 至 " + info[i].myvalue[1].substr(0,10);
                          }catch(e){
                            showinfo = "";
                          }
                          break;
                      case 'select_custom':
                          showinfo = info[i].myvalue;
                          break;
                      case 'select_company_category':
                          showinfo = info[i].myvalue;
                          break;
                      case 'select_category':
                          showinfo = datainfo.categoryname;
                          break;
                      default :
                        showinfo = "";
                        break;
                    }

                    if(showinfo === datainfo.name || showinfo === datainfo.showcomp){//已展示作品名称、参赛单位，跳过
                      continue;
                    }
                    // ele.push(<div key={RandomKey()} style={{textAlign:"left"}}><span className='detail_subtitle' style={{width:"120px",position:"relative",display:"table-cell"}}>{info[i].title}</span><span style={{marginLeft:"150px",position:"relative",display:"table-cell"}}>{showinfo}</span></div>);
                    ele.push(<div key={RandomKey()} style={{textAlign:"left"}}><span className='detail_subtitle' style={{width:"120px",position:"relative",display:"table-cell",fontSize:"14px",fontWeight:"bold"}}>{info[i].title}</span><span style={{marginLeft:"150px",position:"relative",display:"table-cell"}}><div className='detail_subcontent' style={{wordWrap:"break-word"}}>{showinfo}</div></span></div>);
                  }
                }

                //作品描述
                info = datainfo.detail.offer;
                ele.push(<div key={RandomKey()} className="hh">作品描述</div>);
                for(let i = 0;i < info.length;i++){
                  if(info[i].type === 'editor'){
                    ele.push(<div key={RandomKey()} style={{fontSize:"14px",fontWeight:"bold",textAlign:"left",marginTop:"20px"}}>{info[i].title}</div>);
                    ele.push(<div key={RandomKey()} id={info[i].name} name={info[i].name} className='offerContent'></div>);
                    editors.push({"id":info[i].name,"value":info[i].myvalue});
                  } else if(info[i].type === 'upload') {
                    if(info[i].name == 'banner'){
                      uploadpic = info[i].myvalue;
                      continue;
                    } else if(info[i].name == 'showcomplogo'){
                      continue;
                    } else {
                      ele.push(<div key={RandomKey()} style={{fontSize:"14px",fontWeight:"bold",textAlign:"left",marginTop:"20px"}}>{info[i].title}</div>);
                      if(info[i].myvalue)
                        ele.push(<div key={RandomKey()} id={info[i].name} name={info[i].name} className='offerContent'><img src={info[i].myvalue} style="width:100%; " /></div>);
                    }
                  } else{
                    switch(info[i].type){
                      case 'text':
                        showinfo = info[i].myvalue;
                        break;
                      case 'range':
                          try{
                            showinfo = info[i].myvalue[0].substr(0,10) + " 至 " + info[i].myvalue[1].substr(0,10);
                          }catch(e){
                            showinfo = "";
                          }
                          break;
                      case 'select_custom':
                          showinfo = info[i].myvalue;
                          break;
                      case 'select_company_category':
                          showinfo = info[i].myvalue;
                          break;
                      case 'select_category':
                          showinfo = datainfo.categoryname;
                          break;
                      default :
                        showinfo = "";
                        break;
                    }
  
                    if(showinfo === datainfo.name || showinfo === datainfo.showcomp){//已展示作品名称、参赛单位，跳过
                      continue;
                    }
                    // ele.push(<div key={RandomKey()} style={{textAlign:"left"}}><span className='detail_subtitle' style={{width:"120px",position:"relative",display:"table-cell"}}>{info[i].title}</span><span style={{marginLeft:"150px",position:"relative",display:"table-cell"}}>{showinfo}</span></div>);
                    ele.push(<div key={RandomKey()} style={{textAlign:"left"}}><span className='detail_subtitle' style={{width:"120px",position:"relative",display:"table-cell",fontSize:"14px",fontWeight:"bold"}}>{info[i].title}</span><span style={{marginLeft:"150px",position:"relative",display:"table-cell"}}><div className='detail_subcontent' style={{wordWrap:"break-word"}}>{showinfo}</div></span></div>);
                  }
                }

                setCaseinfoElements([...CASEINFO, ele]);

                //设置尺寸
                setTimeout(function(){
                  const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                  let spans = document.getElementsByClassName("detail_subtitle");
                  for(let i = 0;i < spans.length;i++){
                    spans[i].style.width = viewportWidth*0.1 + 'px';
                  }
                  let contentspans = document.getElementsByClassName("detail_subcontent");
                  for(let i = 0;i < contentspans.length;i++){
                    contentspans[i].style.width = viewportWidth*0.5 + 'px';
                  }
                  
                },300);

                //设置评审规则数据
                // ele = [];
                // ele.push(
                //   <div key={RandomKey()} style={{textAlign:"center",background:"black",padding:"5px"}}>
                //   <div key={RandomKey()} style={{height:"40px",lineHeight:"40px",color:"#a4080f",fontSize:"30px",fontWeight:"bold",letterSpacing:"2px"}}>{datainfo.template_title}</div>
                //   <div key={RandomKey()} style={{height:"40px",lineHeight:"40px",color:"#fff",fontSize:"25px",letterSpacing:"2px"}}>评审指标细则</div>
                //   </div>
                // ); 
                // ele.push(<Table key={RandomKey()} columns={columns} dataSource={data} loading="false" showHeader={false} pagination={false} rowClassName="ant-table-row"></Table>);
                // setStandardElements([...STANDARD, ele]); 

                setTimeout(function(){
                  //处理头图
                  if(uploadpic !== '' && uploadpic != null){
                    document.getElementById("upload").innerHTML = '<img src="'+uploadpic+'" style="width:100%; " />';
                  }
                  for(let i = 0;i < editors.length;i++){
                    document.getElementById(editors[i].id).innerHTML = editors[i].value;
                  }
                  //处理附件
                  //pdf
                  let pdflist = [];
                  if(datainfo.detail.attach.PDF.length > 0){
                    for(let i = 0;i < datainfo.detail.attach.PDF.length;i++){
                      pdflist.push(<div key={RandomKey()} className='divPdf' onClick={()=>{view(datainfo.detail.attach.PDF[i],"pdf")}}></div>);
                    }
                  }
                  else{
                    pdflist.push(<p key={RandomKey()} class="nopdf">无PDF文件</p>);
                  }
                  setPdflistElements([...PDFLIST, pdflist]);
                  
                  //video
                  if(datainfo.detail.attach.VIDEO.length > 0){
                    let html = '';
                    for(let i = 0;i < datainfo.detail.attach.VIDEO.length;i++){
                      html += '<video src="'+datainfo.detail.attach.VIDEO[i]+'" controls="controls"  width="100%"></video>';
                    }
                    document.getElementById("VIDEO").innerHTML = html;
                  }
                  else{
                    document.getElementById("VIDEO").innerHTML = '<p class="nopdf">无视频文件</p>';
                  }
                  //pic
                  if(datainfo.detail.attach.PIC.length > 0){
                    let picele = [];
                    let html = [];
                    let btnhtml = [];
                    let btnid = '';
                    let imgid = '';
                    for(let i = 0;i < datainfo.detail.attach.PIC.length;i++){
                      btnid = 'imgbtn' + i;
                      imgid = 'img' + i;
                      html.push(<img key={RandomKey()} id={imgid} src={datainfo.detail.attach.PIC[i]} className="attach_pic" onClick={()=>{view(datainfo.detail.attach.PIC[i],"pic")}} />);
                      btnhtml.push(<div key={RandomKey()}  id={btnid} className="attach_picbtn" onClick={()=>{goimg(i)}}>&nbsp;</div>)
                      attach_piclist.push(datainfo.detail.attach.PIC[i]);
                    }
                    setPiclistElements([...PICLIST, html]);
                    setPicbtnlistElements([...PICBTNLIST, btnhtml]);

                    setTimeout(() => {
                      document.getElementById("img0").style.display = "block";
                      document.getElementById("imgbtn0").style.background = "#ccc";
                      showpic(0);
                    }, 300);
                  }
                  else{
                    document.getElementById("PIC").innerHTML = '<p class="nopdf">无图片文件</p>';
                    // let picele = [];
                    // picele.push(<p className="nopdf">无图片文件</p>);
                    // setPiclistElements([...PICLIST, picele]);
                  }
                },300);

                // let pfele = [];
                // setPingfenElements([...PINGFEN, pfele]);
                return true;
            }
            });
        } catch (error) {
            console.log('catch-error2 ---------- ', error)
            // return false;
        }
        
    };

    let picindex = 0;
    let copacity = 1;
    let hidepichandler;
    let picboxhandler;
  
    const goimg = (imgid)=>{
      picindex = imgid;
      clearTimeout(hidepichandler);
  
      try{
        for(let i = 0;i < attach_piclist.length;i++){
          if(i == picindex){
            document.getElementById("img"+i).style.display = "block";
            document.getElementById("img"+i).style.opacity = 1;
            document.getElementById("imgbtn"+i).style.background = "#ccc";
          }
          else{
            document.getElementById("img"+i).style.display = "none";
            document.getElementById("imgbtn"+i).style.background = "#fff";
          }
        }
        picindex = ((picindex+1) > (attach_piclist.length-1)) ? 0 : (picindex+1);
  
      }catch(e){
        
      }
    }
    const showpic = (showpicindex) => {
      if(attach_piclist.length == 0 || attach_piclist.length == 1){
        return;
      }
      clearTimeout(picboxhandler);
      clearTimeout(hidepichandler);
  
      picindex = showpicindex;
      picboxhandler = setInterval(() => {
        try{
          for(let i = 0;i < attach_piclist.length;i++){
            if(i == picindex){
              document.getElementById("img"+i).style.display = "block";
              document.getElementById("img"+i).style.opacity = 1;
              document.getElementById("imgbtn"+i).style.background = "#ccc";
              // setTimeout(() => {
              //   hidepic("img"+i);
              // }, 2000);
            }
            else{
              document.getElementById("img"+i).style.display = "none";
              document.getElementById("imgbtn"+i).style.background = "#fff";
            }
          }
          picindex = ((picindex+1) > (attach_piclist.length-1)) ? 0 : (picindex+1);
  
        }catch(e){
          clearInterval(picboxhandler);
        }
      }, 3000);
    }
    const hidepic = (imgid) => {
      hidepichandler = setInterval(() => {
        try{
          if(copacity > 0.1){
            document.getElementById(imgid).style.opacity = copacity-0.1;
            copacity -= 0.1;
          }
          else{
            clearInterval(hidepichandler);
            copacity = 1;
            document.getElementById(imgid).style.display = "none";
          }
        }catch(e){
          clearInterval(hidepichandler);
        }
      }, 100);
    }

    window.addEventListener("visibilitychange",function(){
      // if (document.visibilityState === "visible") {
      //   clearTimeout(picboxhandler);
      //   clearTimeout(hidepichandler);
      //   copacity = 1;
      //   picindex = 0;
      //   showpic(0);
      // }
      // else{
      //   console.log("view" + new Date().getTime());
  
      // }
      // window.location.reload();
    })
  

    return (
        <Layout className="layout" style={{display:"block"}}>
        <Header>
          <div style={{width:"70%",marginLeft:"15%"}}>
            <div style={{float:'left', width:"50%"}}>
            <div className="logo" ><img src={config.clientlogo?config.clientlogo:[require("../../../img/tbilogo.png")]} alt="" width="120px" /></div>
            </div>
            <div style={{float:'right',color:"#afa178"}}>
            {currentUserinfo.account} / <a href="javascript:none" onClick={logout} ><span style={{color: "#afa178"}}>退出登录</span></a> / <a href='/#/enter'><span style={{color: "#afa178"}}>返回首页</span></a>
            </div>
          </div>
        </Header>
        <Content style={{padding: '30px 50px 15px',background: "rgb(152,100,201)"}}>
          <div style={{textAlign:"center",width: "100%",height:"150px",opacity:"0.4",marginTop:"-20px",backgroundPositionY:"top",backgroundSize:"20%"}}>
            <img src={[require("../../../img/tbilogo2.png")]} alt="" style={{height:"90%"}} />
          </div>
          <div className="site-layout-content" style={{minHeight:scrheight-250,width:"80%",marginLeft:"10%"}}>
            <div style={{width:'90%',marginLeft:'5%'}}>
              <div>{CASEINFO}</div>
              <div className="hh">附件</div>
              <div className='hh3'>PDF</div>
              <div style={{padding: "0px 100px"}} id="PDF">{PDFLIST}</div>
              <div className="clear"></div>
              <div className="hh3">视频文件</div>
              <div style={{padding: "0px 100px"}} id="VIDEO"></div>
              <div className="clear"></div>
              <div className="hh3">图片文件</div>
              {/* <div style={{padding: "0px 100px"}}>
                <div style={{padding: "0px 0px",marginTop: "40px" ,width:"100%"}}>
                  <div className="layui-carousel" id="PIC" style={{minHeight:"200px",height:"100%"}}>
                    <Slider {...settings} style={{width:"100%",textAlign:"center",minHeight:"200px",height:"100%",marginTop:"auto",marginBottom:"auto"}} >
                      {PICLIST}
                    </Slider>
                  </div>
                </div>
              </div> */}
              <div className="clear"></div>
              <div style={{padding: "0px 100px"}} id="PIC">
              <div style={{padding: "0px 0px",width:"100%"}}>
                <div id="attach_pic" style={{border:"1px solid #ccc",borderRadius:"5px",height:"500px",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center"}}>
                {PICLIST}
                </div>
                <div id="attach_picbtn" style={{textAlign:"center",height:"30px",lineHeight:"30px",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                  {PICBTNLIST}
                </div>
              </div>
            </div>

            </div>
    
            <div className="clear"></div>
        </div>
        </Content>
        <Footer style={{clear:"both",backgroundColor: "white",paddingTop: "30px",height: "115px"}}>
        <p className="copyright">联系方式：{config.footer.phone}</p>
        <p className="copyright">{config.footer.email}</p>
        <p className="copyright">{config.footer.copyright}</p>
        </Footer>
      </Layout>
    )
}

export default App