import Icon,{EditOutlined,FolderViewOutlined,CopyOutlined,DeleteOutlined  } from '@ant-design/icons';
import { Space,Button ,Popconfirm, Modal,List, Tooltip } from 'antd';
import React , { useRef, useState,useEffect }from 'react'
import { ProList } from '@ant-design/pro-components';
import './index.css';
import CustomProList from '../../components/CustomProList';
import { waitTime } from '../../../utils/global.js';
// import { EditIcon } from '../components/icons';
import Addtc from '../addtc/index.jsx';
import {getApiData} from "../../../request/api";
import checkLogin from '../../../request/checkLogin.js'

const screenHeight = document.body.clientHeight;
var PathBASE=window.location.href;
    PathBASE = PathBASE.split("#/")[0]; 

const App = props => {
  // const currentUserinfo = JSON.parse(decrypt(localStorageGet('UserInfo')));
  const actionRef = useRef();

  const [loading_checklist,setChecklistloading] = useState(false);
  const [showEnlist,setShowEnlist] = useState(true);
  const [showAddtc,setShowAddtc] = useState(false);
  const [showModalType,setShowModalType] = useState(false);
  const [curModalInfo,setCurModalInfo] = useState({});
  const [rowInfo, setRowInfo] = useState({});
  const [templateList,setTemplateList] = useState([]);
  const [config,setConfig] = useState({footer:{}});

  // const modeltypes = [
  //   {id:1,name:'A-TopDigital创新营销奖-营销案例类【Case】'},
  //   {id:2,name:'B-TopDigital创新营销奖-创意作品类【Work】'},
  //   {id:3,name:'C-TopDigital创新营销奖-年度专项奖【Special】'},
  //   {id:4,name:'D-TopDigital创新营销奖-年度产品奖【Product】'},
  //   {id:5,name:'E-TopDigital创新营销奖-年度机构奖【Company】'},
  // ];

  const columsName = {
    'version' : {
      title:'状态', 
      width:'5%',
      valueEnum: {
        0: {
          text: '草稿',
          status: 'default',
        },
        1: {
          text: '已提交',
          status: 'success',
        },
      },
    },
    'showcomp' : {
      title:'参赛单位', 
      width:'18%'
    },
    'name' : {
      title:'作品名称', 
      width:'28%'
    },
    'categoryname' : {
      title:'组别', 
      width:'20%'
    },
    'ctime' : {
      title:'提交时间', 
      width:'14%'
    },
    'status' : {
      title:'审核状态', 
      width:'7%',
      valueEnum: {
        "-1": {
          text: '-',
          status: 'default',
        },
        0: {
          text: '审核中',
          status: 'processing',
        },
        1: {
          text: '通过',
          status: 'success',
        },
        2: {
          text: '拒绝',
          status: 'error',
        },
        // 3: {
        //   text: '修改',
        //   status: 'warning',
        // },
      },
    },
    'refusereason' : {
      title:'拒绝原因', 
      width:'7%'
    },
    // 'op' : '操作',
  };
  var columsRow = {};
  Object.keys(columsName).map((field, index) => (
    columsRow[field] = columsName[field]['title']
  ))
  columsRow['id'] = "0";
  columsRow['content'] = columsRow;
  // console.log('columsRow --------------------- ', columsRow)

  // 页面初始化
  useEffect(() => {
    getConfig();
  }, []);
  
  const getConfig = async()=>{
    try {
        return await getApiData('U', 'U_getConfig', {}).then((res) => {
          console.log('U_getConfig-res-----------------', res);
          var flag = checkLogin('U', res.data)
          if (flag) {
            setConfig(res.data.d.config)
          }
        });
      } catch (error) {
        
        return false;
      }
  }

  // 获取模板列表
  const getTemplateList = async () =>{
    var list = [];
    try {
      return await getApiData('U', 'U_templateList', {}).then((res) => {
        console.log('U_templateList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          list = res.data.d.list;
          setTemplateList(list);
        }
      });
    } catch (error) {
      
    }
  }
  
  // 获取我的案例列表
  const getOpList = async (params) =>{
    var list = [];
    try {
      await getApiData('U', 'U_mycaseList', {}).then((res) => {
        console.log('U_mycaseList-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          list = res.data.d.list;
        }
      });
      } catch (error) {
        
      }
    return list;
  }

  // 拷贝案例
  const copyCase = (id) => {
    try {
      getApiData('U', 'U_caseCopy', {caseid:id}).then((res) => {
        console.log('U_caseCopy-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          actionRef.current.reload();
        }
      });
      } catch (error) {
        
      }
  };

  // 删除案例
  const delCase = (id) => {
    try {
      getApiData('U', 'U_delCase', {caseid:id}).then((res) => {
        console.log('U_delCase-res-----------------', res);
        var flag = checkLogin('U', res.data)
        if (flag) {
          actionRef.current.reload();
        }
      });
      } catch (error) {
        
      }
  };

  const closeModal = () => {
    setShowModalType(false);
  };

  const onClickModalType = (item) => {
    setCurModalInfo(item)
    setShowModalType(false);
    setShowEnlist(false);
    setShowAddtc(true);
  };

  const closeAddtc = () => {
    setShowEnlist(true);
    setShowAddtc(false);
    setRowInfo([]);
    setCurModalInfo([]);
  };


  return (

    <div>
          
    <ProList rowKey="id"
      style={{display:showEnlist?"block":"none", minHeight:screenHeight*0.5}}
      headerTitle={"参赛作品"}
        search={{
          filterType: 'light',
        }}
        // pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        loading={loading_checklist}
        actionRef={actionRef}
        toolBarRender={() => {
          return [
              <Button key="btn_add"  shape="round" style={{backgroundColor:"rgb(152,100,201)", color:'white', height:40}}
              onClick={() => {
                getTemplateList();
                setShowModalType(true);
              }}>+ 创建作品</Button>,
          ];
        }} 
        // dataSource={dataSource} 
        request={async (params = {})  => {
          setChecklistloading(true);
          var tableData = [columsRow];
          // console.log('params----------------', params);
          const res = await getOpList(params);
          // console.log('res--------------', res);
          if(res){
            res.map((v,k)=>{
              // console.log('v--------------', v);
              tableData[k+1] = v;
              tableData[k+1]['content'] = {...v};
            })
          }
          // console.log('tableData--------------', tableData);
          setChecklistloading(false);
          return {
              data: tableData,
              // current: res.data.pageNo,
              // pageSize: res.data.pageSize,
              success: true,
              // total: res.data.totalRows,
          };
        }}
        metas={{


          content: {
              dataIndex: 'content',
              search: false,
              render: (text) => (
                <CustomProList 
                  columsName = {columsName}
                  text = {text}
                />
              ),
          },

          actions: {
            render: (text, data) => [
                data.id == '0' ? <div style={{ textAlign:"left",color:'black',width:80 }}>操作</div> : 
              <div key={data.id} style={{textAlign:"left",width:80}}>
                <Space >
                <Tooltip title="预览" placement="bottom" color="rgb(152,100,201)" overlayStyle={{fontSize:12}}>
                <FolderViewOutlined
                  onClick={() => {
                    window.open(PathBASE+"#/enter/detail/?id="+data.id);
                  }}
                /></Tooltip>
                <Tooltip title="复制" placement="bottom" color="rgb(152,100,201)" overlayStyle={{fontSize:12}}>
                <CopyOutlined
                  onClick={() => {
                    console.log(text, data)
                    copyCase(data.id);
                  }}
                /></Tooltip>
                <EditOutlined
                  style={{display:data.version==0 || data.status==2 ? "" : "none"}}
                  onClick={async () => {
                    // console.log(data, text)
                    setRowInfo(data);
                    setCurModalInfo([]);
                    await waitTime(100);
                    setShowEnlist(false)
                    setShowAddtc(true)
                    // setCurClientid(data.id);
                    // setShowEditPassword(true);
                  }}
                />
                <Popconfirm title="确定删除吗?" onConfirm={() => {delCase(data.id)}}>
                  <DeleteOutlined style={{display:data.version==0 ? "" : "none"}} />
                </Popconfirm>

                </Space>
              </div>
            ],
        },

        }} 
        
        />


        {/* 模板列表 */}
        <Modal 
          key="modaltype"
          // title='修改备注'
          open={showModalType}
          width="450px"
          centered
          footer={false}
          onCancel={closeModal}
        >
          <List
              // header={<div>Header</div>}
              // footer={<div>Footer</div>}
              // bordered
              dataSource={templateList}
              renderItem={(item) => (
                <List.Item>
                  <a href="javascript:void(0)" style={{color:'black'}} onClick={()=>{onClickModalType(item)}}> {item.title}</a>
                </List.Item>
              )}
            />
      </Modal>

      <Addtc
        showAddtc = {showAddtc}
        modalinfo = {curModalInfo}
        rowInfo = {rowInfo}
        gconfig = {config}
        closeAddtc = {closeAddtc}
        refreshList={()=>{actionRef.current?.reload();}}
      />

      <div style={{textAlign:"left", marginTop:60, marginLeft:15}}>
        您可免费提交{config.freecasecnt}件作品参赛，超出部分每件需要{config.casefee}元，报名费无论最终是否确认获奖都需收取，请大家填写前仔细查看参赛指南相关要求。
      </div>

      </div>

    // </Content>

);
}
export default App;